import React, { Props, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import SearchSelect from '../Components/TagInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'; 
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Notification from '@material-ui/icons/NotificationsActive';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AppBar from './Common/MainAppBar';
import Button from '@material-ui/core/Button';
import MAppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import { MyBookings } from '../Actions/BookingActions';
import {MyDashboard} from '../Actions/BookingActions';
import { fetchCategories } from '../Actions/CategoryAction';
import Chip from '@material-ui/core/Chip';
import { Divider } from '@material-ui/core';
import './../activity/css/style.css';

import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
 
const drawerWidth = 240;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
  indicator: {
    backgroundColor: '#FFEB3B',
  },
  textAlignCenter: {
    textAlign: 'center'
  }
});


const getStatus = (status) => {
  switch (status) {
    case 0: { return "Pending" }
    case 1: { return "Confirmed" }
    case 2: { return "Rejected" }
    case 3: { return "Canceled" }
    case 4: { return "Completed" }
    case 5: { return "Dispute" }
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } }
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } }
    case 2: { return { backgroundColor: '#673AB7', color: '#FFF' } }
    case 3: { return { backgroundColor: '#263238', color: '#FFF' } }
    case 4: { return { backgroundColor: '#00695C', color: '#FFF' } }
    case 5: { return { backgroundColor: '#f44336', color: '#FFF' } }
  }
}

const StatusChip = (status) => {
  return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
}


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    BookingList: state.Booking.list,
    dashboard: state.Booking.dashboard,
    categories: state.Categories.categories,
    
  }
}



let id = 0;
function createData(name, type, duration, location, status, actions) {
  id += 1;
  return { id, name, type, duration, location, status, actions };
}


const mapDispatchToProps = dispatch => ({
  
  MyBookings: (keyword,bookingDate,tourStart,category) => dispatch(MyBookings(keyword,bookingDate,tourStart,category)),
  MyDashboard:()=>dispatch(MyDashboard()),
  fetchCategories: () => dispatch(fetchCategories()),  
});



class Tours extends Component {

  constructor(props) {
    super(props);

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
      value: 'one',
      total_upcomig:'',
      category:"",            
      searchkey: "",
      bookingdate: "",
      traveldate: "",
    };

    this.props.MyBookings(this.state.searchkey,this.state.bookingdate,this.state.traveldate,this.state.category);
    this.props.fetchCategories();
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    },()=>this.searchBookings());

  };

   handleDateChange (value,field) {
    console.log(value);
    this.setState({
      [field]: value,
    },()=>this.searchBookings());

  };


    handleTabChange = (tab) => {
    this.setState({ value: tab});
  };
  selectCategory = (e) =>{
    this.setState({
      category:e.target.value
    },()=>this.searchBookings())
}
selecttourStatus = (e) =>{
  this.setState({
    tourstatus:e.target.value
  },()=>this.searchBookings())
}
searchBookings = () =>{
  this.props.MyBookings(this.state.searchkey,this.state.bookingdate,this.state.traveldate,this.state.category);

}

handleDateChange (value,field) {
  console.log(value);
  this.setState({
    [field]: value,
  },()=>this.searchBookings());

};


  getStatus(status) {
    switch (status) {
      case 0: { return "Pending" }
      case 1: { return "Confirmed" }
      case 2: { return "Rejected" }
      case 3: { return "Canceled" }
      case 4: { return "Completed" }
      case 5: { return "Dispute" }
    }
  }

  render() {

    const { classes } = this.props;





    return <Fragment>

      <AppBar title="Dashboard"></AppBar>


      <Drawer currentPage="booking"></Drawer>




      <div style={{ width:'auto', marginLeft: drawerWidth+0, paddingTop:35, height:250  }}><div>


        <Table>

          <TableBody>
            <TableRow>
                  <TableCell style={{backgroundColor:'#fff', width:500,fontWeight:600, fontSize:16}}> 
                        

                                <h3>Bookings</h3> 
                  </TableCell>

            </TableRow>

          </TableBody>
        </Table>

     <Table>
          <TableBody>
            <TableRow>


 

              <TableCell style={{ width: 700, fontWeight: 500, paddingTop: 10 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 30, backgroundColor:'#fafbfb' }}>

                
                
<table  style={{width:"100%", textAlign: "left", marginBottom: 5}}>
                    <tr >
                       <td style={{width:"28%"}}>
                              <FormControl className={classes.formControl}>
                                
                                <TextField
                                label='Search'
                                value={this.state.keyword}
                                
                                onChange={this.handleChange('searchkey')}
                                >
                                
                                </TextField>

                                </FormControl>

                               
                      </td>

                         <td alighn="left" style={{width:"15%"}} >

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>           
                            <FormControl className={classes.formControl} >
                                <DatePicker 
                                    value={this.state.bookingdate !== "" && this.state.bookingdate !== null ? new Date(this.state.bookingdate) : null}
                                    autoOk={true} 
                                    clearable={true}    
                                    format="dd/MM/yyyy"
                                    inputVariant="standard"
                                    onChange={(e)=>{this.handleDateChange(e,'bookingdate')}}
                                    style={{width:100 }}                             
                                    margin="normal" label="Booking Date"
                                            />
                                      </FormControl>
                            </MuiPickersUtilsProvider>
                            
                        
                        </td>



                         <td alighn="left"  style={{width:"15%"}}>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>           
                            <FormControl className={classes.formControl} >
                                <DatePicker 
                                    value={this.state.traveldate !== "" && this.state.traveldate !== null ? new Date(this.state.traveldate) : null }
                                    autoOk={true}    
                                    clearable={true}  
                                    format="dd/MM/yyyy"
                                    inputVariant="standard"
                                    onChange={(e)=>{this.handleDateChange(e,'traveldate')}}
                                    style={{width:100 }}                             
                                    margin="normal" label="Tour Start"
                                            />
                                      </FormControl>
                            </MuiPickersUtilsProvider>
                            
                        
                        </td>


                     {/* <TableCell alighn="left">

                        <form className={classes.container} noValidate>
                          <CssTextField
                            id="date"
                            label="Tour Date"
                            type="date"
                            onChange={this.handleChange('traveldate')}
                            defaultValue="2019-09-24"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </form>




                      </TableCell>*/}

                      


                      <td alighn="left" style={{width:"15%"}}>
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink htmlFor="status-native-simple">Tour Catogery</InputLabel>
                          <Select
                            native
                            value={this.state.category}
                            onChange={this.selectCategory}
                            inputProps={{
                              name: 'status',
                              id: 'status-native-simple',
                            }}
                          >
                            <option value="" >All </option>
                            
                            { this.props.categories.map((item)=>{
                                  return <option value={item.id}>{item.name}</option>; 
                            }) }
                            
                          </Select>
                        </FormControl>
                      </td>
                    </tr>
                    
                  </table>
                

                
              <div className="bk-menu" >
                <a style={{cursor:'pointer'}} className={ this.state.value === 'one' ? 'active':null}  onClick={()=>this.handleTabChange('one')}>{ this.props.BookingList.unseen.length > 0 ? (<span>{this.props.BookingList.upcoming.length}</span>):null }Upcoming Tours</a>                
                <a style={{cursor:'pointer'}} className={ this.state.value === 'two' ? 'active':null}  onClick={()=>this.handleTabChange('two')}>On Going Tours</a>             
                <a style={{cursor:'pointer'}} className={ this.state.value === 'three' ? 'active':null}  onClick={()=>this.handleTabChange('three')}>{ this.props.BookingList.pending.length > 0 ? (<span>{this.props.BookingList.pending.length}</span>):null }Pending Tours  </a>              
                <a style={{cursor:'pointer'}} className={ this.state.value === 'four' ? 'active':null}  onClick={()=>this.handleTabChange('four')}>Cancelled Tours</a>
                <a style={{cursor:'pointer'}} className={ this.state.value === 'five' ? 'active':null}  onClick={()=>this.handleTabChange('five')}>Completed Tours</a>                 
                <a style={{cursor:'pointer'}} className={ this.state.value === 'six' ? 'active':null}  onClick={()=>this.handleTabChange('six')}>{ this.props.BookingList.dispute.length > 0 ? (<span>{this.props.BookingList.dispute.length}</span>):null }Disputes</a>
              </div>




                      <div className="tab-pan">
                     <div className="tab-bok">



                  {this.state.value === 'one' && <TabContainer>

                    <div>


                      {this.props.BookingList.unseen.length > 0 ? (
                        <div>
                          <div style={{ paddingLeft: 20 }}><h3>While you were away...</h3></div>
                          <table style={{textAlignright:'right', width: '100%' }} cellSpacing={0}>
                            <thead style={{ fontSize: 16 }}>
                              <tr>
                                <th className={classes.textAlignright}>Booking date</th>
                                <th style={{ width: 200 }}>Tour Name</th>

                                <th className={classes.textAlignCenter}>Client Name</th>
                                <th className={classes.textAlignCenter}>Pax</th>
                                <th className={classes.textAlignCenter}>Tour start</th>
                                <th className={classes.textAlignCenter}>Status</th>
                                <th></th>
                              </tr>

                            </thead>
                            <tbody style={{ fontSize: 15 }}>
                              

                              {this.props.BookingList.unseen.map(row => (
                              
                                <tr>
                                  <td className={classes.textAlignLeft}>{row.created_at}</td>
                                  <td style={{ width: 200 }}>{row.activity.title} 
                                                                {row.alert_supplier === 1 ? (<Notification color="error" fontSize="small" />) : null}<br/>
                                                                <small>Tour code : {row.activity.tour_id} </small>


                                  </td>

                                  <td className={classes.textAlignLeft}> {row.user.guest_profile.first_name} {row.user.guest_profile.last_name}</td>
                                  <td className={classes.textAlignLeft}>{row.num_adults}A/{row.num_children}C/{row.num_infants}I</td>
                                  <td className={classes.textAlignLeft}>{row.start_date}</td>
                                  <td className={classes.textAlignLeft}>{StatusChip(row.status)}</td>
                                  <td className={classes.textAlignLeft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{background:"#f7a414",textAlign:'center'}}><b>View</b></Button></td>
                                </tr>
                              ))}
                            </tbody> 
                          </table>

                          <div style={{ marginTop: 50 }}></div>
                          <Divider />
                          <div style={{ marginBottom: 50 }}></div>
                        </div>
                      ) : null}



                      <table style={{ width: '100%' }} cellSpacing={0}>
                        <thead style={{ fontSize: 16}}>
                          <tr>
                            <th className={classes.textAlignCenter}>Booking date</th>
                            <th>Tour Name</th>

                            <th className={classes.textAlignCenter}>Client Name</th>
                            <th className={classes.textAlignCenter}>Pax</th>
                            <th className={classes.textAlignCenter}>Tour start</th>
                            <th className={classes.textAlignCenter}>Status</th>
                            <th></th>
                          </tr>

                        </thead>
                        <tbody style={{ fontSize: 15 }}>
                          {this.props.BookingList.upcoming.map(row => (
                            <tr>
                              <td className={classes.textAlignleft}>{row.created_at}</td>
                              <td>{row.activity.title} <br/><small>Tour code : {row.activity.tour_id} </small></td>
                              <td className={classes.textAlignLeft}> {row.user.guest_profile.first_name} {row.user.guest_profile.last_name}</td>
                              <td className={classes.textAlignLeft}>{row.num_adults}A/{row.num_children}C/{row.num_infants}I</td>
                              <td className={classes.textAlignLeft}>{row.start_date}</td>
                              <td className={classes.textAlignleft}>{StatusChip(row.status)}</td>
                              <td className={classes.textAlignLeft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{background:"#f7a414",textAlign:'center'}}><b>View</b> </Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>


                  </TabContainer>}





                  {this.state.value === 'two' && <TabContainer>

                    <div>



                      <table style={{ width: '100%' }} cellSpacing={0}>
                        <thead style={{ fontSize: 16 }}>
                          <tr>
                            <th className={classes.textAlignCenter}>Booking date</th>
                            <th>Tour Name</th>

                            <th className={classes.textAlignCenter}>Client Name</th>
                            <th className={classes.textAlignCenter}>Pax</th>
                            <th className={classes.textAlignCenter}>Tour start</th>
                            <th className={classes.textAlignCenter}>Status</th>
                            <th></th>
                          </tr>

                        </thead>
                        <tbody style={{ fontSize: 15 }}>
                          {this.props.BookingList.ongoing.map(row => (
                            <tr>
                              <td className={classes.textAlignLeft}>{row.created_at}</td>
                              <td>{row.activity.title} <br/> <small> Tour code :({row.activity.tour_id})</small> </td>
                              <td className={classes.textAlignLeft}> {row.user.guest_profile.first_name} {row.user.guest_profile.last_name}</td>
                              <td className={classes.textAlignLeft}>{row.num_adults}A/{row.num_children}C/{row.num_infants}I</td>
                              <td className={classes.textAlignLeft}>{row.start_date}</td>
                              <td className={classes.textAlignLeft}>{StatusChip(row.status)}</td>
                              <td className={classes.textAlignLeft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{background:"#f7a414",textAlign:'center'}}><b>View</b> </Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>


                  </TabContainer>}

                  {this.state.value === 'three' && <TabContainer>

                    <div>



                      <table style={{ width: '100%' }} cellSpacing={0}>   
                        <thead style={{ fontSize: 16}}>
                          <tr>
                            <th className={classes.textAlignCenter}>Booking date</th>
                            <th>Tour Name</th>

                            <th className={classes.textAlignCenter}>Client Name</th>
                            <th className={classes.textAlignCenter}>Pax</th>
                            <th className={classes.textAlignCenter}>Tour start</th>
                            <th className={classes.textAlignCenter}>Status</th>
                            <th></th>
                          </tr>

                        </thead>
                        <tbody style={{ fontSize: 15 }}>
                          {this.props.BookingList.pending.map(row => (
                            <tr>
                              <td className={classes.textAlignLeft}>{row.created_at}</td>
                              <td>{row.activity.title} <br/> <small> Tour code: ({row.activity.tour_id})  </small> </td>
                              <td className={classes.textAlignLeft}> {row.user.guest_profile.first_name} {row.user.guest_profile.last_name}</td>
                              <td className={classes.textAlignLeft}>{row.num_adults}A/{row.num_children}C/{row.num_infants}I</td>
                              <td className={classes.textAlignLeft}>{row.start_date}</td>
                              <td className={classes.textAlignLeft}>{StatusChip(row.status)}</td>
                              <td className={classes.textAlignLeft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{background:"#f7a414",textAlign:'center'}}><b>View</b></Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>


                  </TabContainer>}

                  {this.state.value === 'four' && <TabContainer>

                    <div>



                      <table style={{ width: '100%' }} cellSpacing={0}>
                        <thead style={{ fontSize: 16 }}>
                          <tr>
                            <th className={classes.textAlignCenter}>Booking date</th>
                            <th>Tour Name</th>

                            <th className={classes.textAlignCenter}>Client Name</th>
                            <th className={classes.textAlignCenter}>Pax</th>
                            <th className={classes.textAlignCenter}>Tour start</th>
                            <th className={classes.textAlignCenter}>Status</th>
                            <th></th>
                          </tr>

                        </thead>
                        <tbody style={{ fontSize: 15 }}>
                          {this.props.BookingList.cancelled.map(row => (
                            <tr>
                              <td className={classes.textAlignLeft}>{row.created_at}</td>
                              <td>{row.activity !== null ? row.activity.title : ""} <br/> <small> Tour code: ({row.activity !== null ? row.activity.tour_id : ""})  </small> </td>
                              <td className={classes.textAlignLeft}> {row.user.guest_profile.first_name} {row.user.guest_profile.last_name}</td>
                              <td className={classes.textAlignLeft}>{row.num_adults}A/{row.num_children}C/{row.num_infants}I</td>
                              <td className={classes.textAlignLeft}>{row.start_date}</td>
                              <td className={classes.textAlignLeft}>{StatusChip(row.status)}</td>
                              <td className={classes.textAlignLeft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{background:"#f7a414",textAlign:'center'}}><b>View</b> </Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>


                  </TabContainer>}          

                  {this.state.value === 'five' && <TabContainer>

<div>



  <table style={{ width: '100%' }} cellSpacing={0}>
    <thead style={{ fontSize: 16 }}>
      <tr>
        <th className={classes.textAlignCenter}>Booking date</th>
        <th>Tour Name</th>

        <th className={classes.textAlignCenter}>Client Name</th>
        <th className={classes.textAlignCenter}>Pax</th>
        <th className={classes.textAlignCenter}>Tour start</th>
        <th className={classes.textAlignCenter}>Status</th>
        <th></th>
      </tr>

    </thead>
    <tbody style={{ fontSize: 15 }}>
      {this.props.BookingList.completed.map(row => (
        <tr>
          <td className={classes.textAlignLeft}>{row.created_at}</td>
          <td>{row.activity.title} <br/> <small> Tour code:({row.activity.tour_id})</small> </td>
          <td className={classes.textAlignLeft}> {row.user.guest_profile.first_name} {row.user.guest_profile.last_name}</td>
          <td className={classes.textAlignLeft}>{row.num_adults}A/{row.num_children}C/{row.num_infants}I</td>
          <td className={classes.textAlignLeft}>{row.start_date}</td>
          <td className={classes.textAlignLeft}>{StatusChip(row.status)}</td>
          <td className={classes.textAlignLeft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{background:"#f7a414",textAlign:'center'}}><b>View</b> </Button></td>
        </tr>
      ))}
    </tbody>
  </table>

</div>


</TabContainer>}
{this.state.value === 'six' && <TabContainer>

<div>



  <table style={{ width: '100%' }} cellSpacing={0}>
    <thead style={{ fontSize: 16 }}>
      <tr>
        <th className={classes.textAlignCenter}>Booking date</th>
        <th>Tour Name</th>

        <th className={classes.textAlignCenter}>Client Name</th>
        <th className={classes.textAlignCenter}>Pax</th>
        <th className={classes.textAlignCenter}>Tour start</th>
        <th className={classes.textAlignCenter}>Status</th>
        <th></th>
      </tr>

    </thead>
    <tbody style={{ fontSize: 15 }}>
      {this.props.BookingList.dispute.map(row => (
        <tr>
          <td className={classes.textAlignLeft}>{row.created_at}</td>
          <td>{row.activity.title} <br/> <small> Tour code:({row.activity.tour_id})  </small> </td>
          <td className={classes.textAlignLeft}> {row.user.guest_profile.first_name} {row.user.guest_profile.last_name}</td>
          <td className={classes.textAlignLeft}>{row.num_adults}A/{row.num_children}C/{row.num_infants}I</td>
          <td className={classes.textAlignLeft}>{row.start_date}</td>
          <td className={classes.textAlignLeft}>{StatusChip(row.status)}</td>
          <td className={classes.textAlignLeft}><Button variant="contained" color="primary" size="small" component={Link} to={'/BookingDetails/' + row.id} className={classes.button} style={{background:"#f7a414",textAlign:'center'}}><b>View</b></Button></td>
        </tr>
      ))}
    </tbody>
  </table>

</div>


</TabContainer>}  
      </div>
      </div>
                </Paper>

              </TableCell>

            </TableRow>

          </TableBody>
        </Table>

      </div>
      </div>

    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);