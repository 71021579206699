import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import LabelIcon from '@material-ui/icons/Label';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class OptionsBox extends React.Component {


    constructor(props) { 
        super(props);

        this.state = {
            editMode: false,
            itemText:null,
            addedOptions:[],
            updated:false
        }
    }


    static getDerivedStateFromProps(props, state) {
        
        if(Array.isArray(props.value)){
            if( state.updated === false){
                return {
                    editMode: props.value.length === 0,
                    addedOptions:props.value,
                    updated:true,
                }
            }
        }
    }


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }


    handleApply =()=>{

        if(this.props.required === true){
            if(this.state.addedOptions === null){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }

            if(this.state.addedOptions.length === 0){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }
        }

        this.props.onChange(this.state.addedOptions);

        this.setState({
            editMode:false
        });

        
    }

    handleTextChange = (e) => {
        this.setState({
                            itemText:e.target.value  
                        });
    }


    addItem = () =>{
        this.setState({
            ...this.state,
            addedOptions:[
                ...this.state.addedOptions,
                this.state.itemText,
            ]
        },()=> {
            this.setState({
                itemText:"",
            });

            this.props.onChange(this.state.addedOptions);
        })
    }

    addItemFromList = (value) =>{
        this.setState({
            ...this.state,
            addedOptions:[
                ...this.state.addedOptions,
                value
            ]
        }
        ,()=>this.props.onChange(this.state.addedOptions))
    }

    removeItem(index) {
        this.setState({
            addedOptions: this.state.addedOptions.filter((_, i) => i !== index)
        },()=>this.props.onChange(this.state.addedOptions))
      }

    render() {

        
        
        return <div>
            {this.state.editMode === false ? (<div className="supplier-block terms-conditions-details">
                <a onClick={()=>this.handleEditMode()} className="edit-block"></a>
                <div className="block-content">
                    <div className="inner-block-content">
                        <div className="one-full-col ">
                            <div className="supply-col">
                                <label>{this.props.label}</label>
                                <div className="col-variable">
                                    <div className="inner-description">
                                        
                                        <table style={{width:'100%'}}>

                                            { this.state.addedOptions.map((item,index)=>{
                                                return <tr>
                                                            <td style={{width:'5%',padding:2,verticalAlign:'top'}}><LabelIcon style={{fontSize:13}} /></td>
                                                            <td style={{width:'70%',padding:2,verticalAlign:'top'}}>{item}</td>
                                                            <td style={{width:'20%',padding:2}}></td>
                                                        </tr>;
                                            })}
                                                
                                        </table>
                                        
                                        
                                        
                                        
                                        
                                        
                                        
                                        
                          </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>

            </div>
            ) : (<div className="supplier-block terms-conditions-details editable">               
                <div className="block-content">
                    <div className="inner-block-content">
                        <div className="one-full-col ">
                            <div className="supply-col">
                                <label>{this.props.label}</label>
                                <div className="col-variable">
                                    <div className="inner-description">
                                    <table style={{width:'100%'}}>

                                                {this.state.addedOptions.map((item,index)=>{
                                                    return <tr>
                                                                <td style={{width:'5%',padding:2,verticalAlign:'top'}}><LabelIcon style={{fontSize:13}}/></td>
                                                                <td style={{width:'70%',padding:2,verticalAlign:'top'}}>{item}</td>
                                                                <td style={{width:'20%',padding:2}}><IconButton onClick={()=>this.removeItem(index)}>
                                                                                                        <CloseIcon/>                
                                                                                                    </IconButton></td>
                                                            </tr>;
                                                })}
                                                    
                                                    { this.props.templates !== undefined ? (
                                                                        <tr>
                                                                        <td style={{width:'5%',padding:5,verticalAlign:'top'}}></td>
                                                                        <td style={{width:'70%',padding:5,verticalAlign:'top'}}>
                                                                        <ExpansionPanel >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Select</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
             <table cellSpacing={0} style={{width:'100%'}}>
             { this.props.templates.map((pol,index)=>{
                return <tr>
                <td style={{width:'85%',padding:15}}>{pol.content}</td>
                <td style={{width:'15%'}}>

                <IconButton onClick={()=>this.addItemFromList(pol.content)}>
                        <AddIcon/>                
                </IconButton>

                </td>
            </tr>
            }) }
                 
            </table>                                           
              
        </ExpansionPanelDetails>
        <ExpansionPanelActions>

            
              
        </ExpansionPanelActions>
      </ExpansionPanel>    
                                                                        
                                                                        </td>
                                                                        <td style={{width:'20%',padding:5}}></td>
                                                                        </tr>
                                                                      
                                                                    ):null}
                                                    
                                                    
                                                      
                                                </table>

                                        {/* <textarea className="textarea-inner-description" maxLength={this.props.maxChars} onChange={this.handleTextChange} value={this.state.itemText}></textarea> */}
                                        <input type="text" name="" id="" style={{height:"30px",width:"100%"}} className="textarea-inner-description" maxLength={this.props.maxChars} onChange={this.handleTextChange} value={this.state.itemText} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        <div className="button-container" style={{paddingTop:8}}>

                        
                                <a onClick={()=>this.addItem()} className="button-apply">Add Item</a> &nbsp; 
                                { this.props.showDone === true ? (<a onClick={()=>this.handleApply()} className="button-apply">Done</a>) : null}                
                                
                        </div>
                    </div>
                </div>
                
            </div>)}
        </div>
    }
}

OptionsBox.defaultProps ={
    showDone:true,
    maxChars:600
}

export default OptionsBox;