import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';

export function ShowRefund(data){
    return function(dispatch){
        dispatch(showCreateRefundAction(true,data,(status,remark,data)=>{
            dispatch(respondRefund(status,remark,data));
        }))
    }
}




export function showCreateRefundAction(show,data,on) {
    return {
        type: 'SHOW_REFUND_REQUEST',
        show: show,
        data:data,
        on:on
    }
}


export function respondRefund(status,remark,data) {
   return function(dispatch){
    dispatch(startLoading());
    dispatch(SnackBar(true,'Adding your response...','info'));
    fetch(API_URL+'/api/supplier/finance/refund/response',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
        },
        body:JSON.stringify({status:status,remark:remark,data:data}),
    }).then(handleHTTPError)
    .then((response)=>{
          dispatch(endLoading());
          response.json().then((json)=>{             
              dispatch(SnackBar(true,'Response saved','success'));
              dispatch(GetRefunds());                                    
          });
    }).catch((error)=>{              
      dispatch(endLoading());            
      dispatch(SnackBar(true,'Could not process request','error'));
    });
   }
}

export function GetRefunds() {
    return function(dispatch){
    
     dispatch(SnackBar(true,'Loading request...','info'));
     fetch(API_URL+'/api/admin/finance/refund/list',{
         method: 'GET',
         headers: {
           'Content-Type': 'application/json;charset=utf-8',
           'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
         },
         
     }).then(handleHTTPError)
     .then((response)=>{
           dispatch(endLoading());
           response.json().then((json)=>{             
               dispatch(storeRefunds(json))                                                   
           });
     }).catch((error)=>{              
       dispatch(endLoading());            
       dispatch(SnackBar(true,'Could not process request','error'));
     });
    }
 }

 export function storeRefunds(data) {
    return {
        type: 'STORE_REFUNDS',
        list: data,      
    }
}


export function ViewRefund(data){
    return function(dispatch){
        dispatch(ViewRefundForm(true,data,(status,data)=>{
            dispatch(viewRefundAction(status,data));
        }))
    }
}

export function ViewRefundForm(show,data,on) {
    return {
        type: 'SHOW_REFUND_REQUEST_FORM',
        show: show,
        data:data,
        on:on
    }
}


export function viewRefundAction(status,data) {
    return function(dispatch){
     dispatch(startLoading());
     dispatch(SnackBar(true,'Creating new request...','info'));
     fetch(API_URL+'/api/admin/finance/refund/update?rid='+data.id+'&s='+status,{
         method: 'GET',
         headers: {
           'Content-Type': 'application/json;charset=utf-8',
           'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
         },         
     }).then(handleHTTPError)
     .then((response)=>{
           dispatch(endLoading());
           response.json().then((json)=>{             
               dispatch(SnackBar(true,'Request created','success'));                                    
           });
     }).catch((error)=>{              
       dispatch(endLoading());            
       dispatch(SnackBar(true,'Could not process request','error'));
     });
    }
 }


 export function GetInvoices() {
    return function(dispatch){
    
     dispatch(SnackBar(true,'Loading request...','info'));
     fetch(API_URL+'/api/supplier/finance/invoices/list',{
         method: 'GET',
         headers: {
           'Content-Type': 'application/json;charset=utf-8',
           'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
         },
         
     }).then(handleHTTPError)
     .then((response)=>{
           dispatch(endLoading());
           response.json().then((json)=>{             
               dispatch(storeInvoices(json))                                                   
           });
     }).catch((error)=>{              
       dispatch(endLoading());            
       dispatch(SnackBar(true,'Could not process request','error'));
     });
    }
 }

 export function storeInvoices(data) {
    return {
        type: 'STORE_INVOICES',
        list: data,      
    }
}