import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarRatingComponent from 'react-star-rating-component';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';


const getStatus = (status) => {
  switch (status) {
    case 0: { return "Pending" }
    case 1: { return "Published" }
    case 2: { return "Un Published" }
    case 3: { return "Rejected" }
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } }
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } }
    case 2: { return { backgroundColor: '#673AB7', color: '#FFF' } }
    case 3: { return { backgroundColor: '#263238', color: '#FFF' } }
    case 4: { return { backgroundColor: '#00695C', color: '#FFF' } }
    case 5: { return { backgroundColor: '#f44336', color: '#FFF' } }
  }
}

const StatusChip = (status) => {
  return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
}



class ReviewBox extends React.Component {


  constructor(props) {
    super(props);

    this.state = {


    };

  }












  render() {




    return (

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <table width="100%">
              <tr>
                  <th width="33.33%" style={{textAlign:'left'}}>{this.props.review.user.guest_profile.first_name}&nbsp;{this.props.review.user.guest_profile.last_name}</th>
                  <th width="33.33%">{new Date(this.props.review.review_datetime).getDate()+'-'+(new Date(this.props.review.review_datetime).getMonth()+1)+'-'+(new Date(this.props.review.review_datetime).getFullYear())}</th>
                  <th width="33.33%"><StarRatingComponent
                          name="rate2"
                          editing={false}
                          renderStarIcon={() => <span></span>}
                          starCount={5}
                          value={this.props.review.review_rating}
                          renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal' }} >★</i>}
                        /></th>
                   <td>{StatusChip(this.props.review.status)}</td>
              </tr>
          </table>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'block'}}>

            <p><b>Booking ref :</b> {this.props.review.booking.booking_reference}</p>
            <p>{this.props.review.review_feedback}</p>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>


        </ExpansionPanelActions>
      </ExpansionPanel>


    )
  };

}

export default ReviewBox;
