import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';

import {ShowAgreement} from '../../Actions/AuthActions';


import { compose } from 'redux';
import { connect } from 'react-redux'; 


import { Logout,AgreeToTerms } from '../../Actions/AuthActions';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
    return {       
      showAgreement:state.Auth.showAgreement
    }
}

const mapDispatchToProps = dispatch => ({
    Logout:()=>dispatch(Logout()),
    AgreeToTerms:()=>dispatch(AgreeToTerms())
});

  
  

   





class agreement extends React.Component {
    constructor(props){
        super(props);

        this.state = {            
            reference:"",
            showAgreement:false,
        };
    }

    handleConfirmaton = () =>{
      this.props.agreemetAction(this.state.reference,this.props.agreementData);
      this.props.showPaymentAction(false,null,null)
    }

    handleChage = (e,field)=>{
        this.setState({
            [field]:e.target.value
        });
    }

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);

    }

    render() {

        return (
            <div>             
              <Dialog
        
        open={this.props.showAgreement}
        TransitionComponent={Transition}
        keepMounted
        
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" textalign='center'>  {"TOUR OPERATOR AGREEMENT"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <div>
                  <p>Who we are?</p>

                  <p>AKTIVITAR is a privately held Sri Lankan company that operates an online marketplace 
                  that enables users to discover, reach and book incredible experiences                   
                  island wide with direct access to thousands of bookable activities which can easily     
                  match with the user requirements.  Hence, Aktivitar makes it simple for travelers to   
                  find exactly what they want to do. Optimized for mobile booking, Aktivitar lets        
                  travelers plan in advance; book ahead and skip the queues at the biggest               
                  attractions in Sri Lanka.                                                               

                  </p>

                  <p>We also feature:</p>

                                       <ul type="disc">
                                        <li>24-hour cancellation policy </li>
                                        <li>The lowest price guarantee</li>
                                        <li>Customer reviews and Recommendations</li>
                                        <li>24/7 customer care (English Only)</li>
                                        <li>24/7 Supplier assistance (Sinhala / English)</li>
                                      </ul>
                    

                      <p textcolor="black" > <b>Marketplace Agreement</b></p>

                      <p>Between:<br/>
                       <b> Aktivitar</b> - 164/B, New Kandy Rd, Kothalawala, Kaduwela, Sri Lanka 
                                          (the " Booking Platform ") <br/>
                        and: <br/>
                        Operator <br/> 
                        The admin of the supplier account who has the right to do trading in the online 
                        marketplace of Aktivitar as a tour operator. <br/><br/>

                        (the " Operator ", the Operator and the Booking Platform hereinafter also jointly 
                        referred to as the " Parties " and each as a " Party ") <br/><br/>

                        This Agreement (the “Agreement”) is made between the Operator and the Booking Platform. 
                        The Operator hereby appoints the Booking Platform as an intermediary 
                        solely for the purpose of collecting payments made by customers on behalf of the 
                        Operator under the following terms:  <br/><br/></p>

                      

                           <ol type="1" >
                                      <li><h3> <b>APPLICATION OF TERMS AND CONDITIONS</b>  </h3> 
                          The general terms and conditions of Booking Platform ("<b> Terms and Conditions</b> "), as amended
                          from time to time (the current version of which is mentioned below – Part A) as well 
                          as the ’Processor and Non-Disclosure Agreement’ covering the General Data 
                          Protection Regulation (as mentioned – Part B), shall apply to this Agreement. In case 
                          of any inconsistency or discrepancy between the provisions of this Agreement and 
                          the Terms and Conditions, the provisions of this Agreement shall prevail over the 
                          Terms and Conditions. </li>

                                      <li>  <h3> <b>   ONLINE MARKETPLACE </b>  </h3> 
                                The Booking Platform owns www.Aktivitar.com, an online tour marketplace (the “Marketplace”),<br/>
                                where customers (the " Customers ") compare and book tours. Tours are bookable in 2 currencies <br/>
                                (USD and LKR) whereby the booking currency and price is based on the Customer’s location. The Booking Platform is entitled to add more selling currencies in the future.
                                      </li>


                                       <li>

                               <h3> <b>   DUTIES OF THE OPERATOR AS PRODUCT SUPPLIER </b>  </h3> 

                                
                                      3.1 &emsp;The Operator is obliged to provide the Booking Platform with a full list of tours and ancillary services to be offered and sold by the Booking Platform (the " Operator Services "). The Operator can either provide the Booking Platform with an XML file or a feed from a reservation platform connected to the Booking Platform for all its trips, which must include a detailed day-to-day itinerary, trip notes, departure dates, pricing and availability (the “Feed”). The Operator is responsible to ensure that any changes of the Operator Services are immediately reflected in the Feed.
                                      
                                      <br/><br/>3.2 &emsp;If the Operator does not have Feed, the Operator must update its Operator Services (including detailed day-to-day itinerary, trip notes, departure dates, pricing and availability) on a regular basis by using the Booking Platform’s Dashboard (the "<b>Dashboard</b>") to ensure the information in the Marketplace is true and correct. The Dashboard is accessible using the Operator’s unique username and password.
                                      
                                      <br/><br/>3.3 &emsp;The Operator, not the Booking Platform, is solely responsible for honoring any confirmed bookings and making available any Operator Services reserved by Customers through the Marketplace. Operators working with the Booking Platform are responsible for making sure that their tour listings on the Marketplace are up to date so that travelers who wish to book their tours are presented with correct information. The Operator must provide the Customer with documents/vouchers either by uploading to the Booking Platform’s private "Booking Conversation Page" messaging system ("<b>BCP</b>") or emailing them directly to the Customer. Tour documents/vouchers should only be provided to the Customer after full payment has been made via BCP.
                                      
                                      <br/><br/>3.4 &emsp;The Operator is obliged to provide the Booking Platform with a list of any promotions, early birds and offers that are being marketed by the Operator.

                                      <br/><br/>3.5 &emsp;The Operator may not provide to the Booking Platform any content, that,<br/>

                                       <ol type="A">
                                            <li> is threatening, coercing, defamatory (e.g. mobbing), offensive, nudging and/or pornographic of any kind;     </li>
                                            <li>is racist, race baiting, anti-constitutional and/or prosecutable/illegal of any kind; or violates applicable law, especially property rights of third parties, e.g. copyrights and trademark law.
                                                  </li>
                                      
                                            </ol>
                                      Booking Platform shall not be obliged to control all content that is provided by the Operator. However, in the event Booking Platform becomes aware of any violation 
                                      of third-party-rights by any content provided by the Operator, Booking Platform 
                                      reserves the right to delete such content within an appropriate period.
                                      
                                      <br/><br/>3.6 &emsp;The Operator is solely responsible for all content that is added to the Booking Platform. Accordingly, the Operator represents and warrant that:

                                              <ol type="A">
                                              <li>The Operator is the sole and exclusive owner of all Content made available on the Booking Platform or the Operator has all rights, licenses, consents and releases that are necessary to grant to the Booking Platform the rights in Content, as contemplated under these Terms
                                                          </li>
                                              <li>Neither the Content nor the Operator posting, uploading, publication, submission or transmittal of the Content or the Booking Platform's use of the Member Content (or any portion thereof) on, through or by means of the Booking Platform Services will infringe, misappropriate or violate a third party's patent, copyright, trademark, trade secret, moral rights or other proprietary or intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.
                                                              </li>
                                              
                                                </ol>

                                   
                                      <br/><br/>3.7 &emsp;The Operator must be reachable within a maximum of 1 business day either by telephone or e-mail
   
                                        for the Booking Platform’s Accounts Team to be able to check the status of pending booking requests.
                                     
                                      <br/><br/>3.8 &emsp;The Operator must respond to booking requests within a maximum timeframe of 48 hours, otherwise the booking request will get cancelled automatically and the Customer’s payment/s, if any, will be refunded. The Operator is further obliged to respond to questions by Customers within a maximum timeframe of 48 hours.

                                   
                                      <br/><br/>3.9 &emsp;The Operator must inform the Booking Platform of any financial bonding (e.g. ABTA travel insurance) they may have.
                                    
                                      <br/><br/>3.10 &emsp;The Operator must assist the Booking Platform with any Customer complaints arising out of or in connection with the Operator Services in a way that the highest possible standard of customer service is provided to the Customer.
                                    
                                      <br/><br/>3.11 &emsp;The Operator must have any and all rights (license rights) necessary to operate the Operator Services offered through the Marketplace according to the laws applicable in Sri Lanka. 
                                                                   
                                    
                                    
                                      </li>

                                      <li>
                                      <h3> <b>       DUTIES OF THE BOOKING PLATFORM AS PROVIDER OF THE MARKETPLACE </b>  </h3>

                                                  4.1. &emsp;The Booking Platform is obliged to provide the Customer with all details of the Operator Services in
                                                   accordance with the information provided by the Operator to the Booking Platform.

                                                <br/><br/>  4.2. &emsp;The Booking Platform shall communicate to the Customer the booking terms of the Operator. The Customer must agree to such booking terms in order to complete a booking with the Booking Platform.

                                                <br/><br/>  4.3. &emsp;The Booking Platform shall promptly inform the Operator about cancellations, amendments, bookings and/or traveler complaints regarding the Operator Services.

                                                <br/><br/> 4.4.&emsp; The Booking Platform is obliged to pull automatic daily updates from the Feed to ensure the information presented in the Marketplace is as up-to-date as possible. The Booking Platform may add additional Feed providers.


                                      </li>


                                      <li>
                                              <h3> <b>      COMMUNICATION </b>  </h3>

                                    5.1. &emsp;With respect to any Operator Services offered through the Marketplace, the Operator is exclusively required to use the BCP to close any bookings and communicate with the Customer.

                                    <br/><br/>  5.2. &emsp;If an Operator violates section 5.1, the Service Fee payable according to section 6 and 7 will be the
                                    same as if the booking had been closed on the Booking Platform. The Booking Platform is entitled
                                    to claim compensation of costs and expenses that arise due to section 5.1 violated by the Operator
                                    at a minimum cost of $ 75, billed to the Operator.

                                    <br/><br/>  5.3.&emsp;Irrespective of section 5.2, should section 5.1 be violated by the Operator for the first time, there
                                    will be a written warning emailed from the Booking Platform; should it be violated a second time,
                                    the Operator Services will be suspended from showing in the Marketplace for 3 months; should a
                                    third situation occur, the Booking Platform is entitled to immediately terminate this Agreement.
                                      </li>


                                       <li>
                                         <h3> <b>      SERVICE FEE </b>  </h3>

                                          The Operator agrees to provide the Booking Platform with a service fee (the " Service Fee ") for the Operator Services sold via the Marketplace corresponding to (i) 20% of the tour price of all the Operator’s tours sold and (ii) 10% of the price for any ancillary products and/or services sold or (iii) in case of cancellation (by a Customer) of an Operator Service sold via the Marketplace, 10% of the cancellation fee charged by the Operator; whereby in each case ((i) to (iii)) the Service Fee shall be deducted from the gross price for the Operator Services at the time of payout according to the provisions of section 7. The Booking Platform does not charge a service fee on local payments or food funds (e.g. payments which are made in cash directly to the local supplier at the start of a tour). 
                                      </li> 

                                      <li>
                                       <h3> <b>  PAYMENT AND PAYOUT TERMS </b>  </h3>

                                      7.1. &emsp;The Booking Platform will collect the deposit (specified by the Operator) and balance of payment or the full tour payment, as the case may be, from the Customer booking the Operator’s Services with the Booking Platform. Payment is collected in the currency of where the Customer is located (see section 2).

                                      <br/><br/> 7.2. &emsp;The Booking Platform works with the Operator on ‘statement’. This means the Booking Platform is not being charged for individual bookings made when bookings are confirmed. One invoice is created for all the completed bookings of every calendar month and make available in the Finance section of the Operator Dashboard on the 5th day of the next month. The payment will be done within 20 days from the invoice date provided that the correct bank details are available in the Supplier Dashboard. The Booking Platform does not charge any fees for this service, however any merchant fee levied by the Operator's bank will be the responsibility of the Operator. Operator should notify issues regarding the payments and invoices to accounts@aktivitar.com.


                                      </li>

                                      <li>
                                        <h3> <b>      MARKETING </b>  </h3>

                                    The Booking platform enable the operator to publish deals and discounts for the tours and activities they have listed. If the operator wants to publish them with paid advertisements, it should be notified to supplier@aktivitar.com. Aktivitar does marketing to promote the booking platform to the target audience and maximize the leads for the web. Aktivitar does not charge anything from the operator for the marketing and promotions of the booking platform unless the operator wants to promote a tour through the booking platform.
                                     </li>


                                      <li>

                                          <h3> <b>    TERM </b>  </h3>

                                    This Agreement shall come into force on the date of this Agreement and shall continue for a period of 12 months (the “Term”). This Agreement shall be automatically extended for another Term unless either Party terminates this Agreement. Either Party can terminate this Agreement at any time pursuant to section 10 of this Agreement.
                                      </li>


                                        <li>
                                            <h3> <b>   TERMINATION OF THE AGREEMENT  </b> </h3> 

                                   10.1. &emsp;Each Party is entitled to terminate this Agreement in writing within a period of one month to the end of a calendar month.

                                  <br/><br/>  10.2. &emsp;Notwithstanding section 10.1 either Party shall have the right to terminate this Agreement in writing
                                    with immediate effect for good cause. The Parties agree that such good cause shall exist, but not limited to if:
                                    <br/>- the other Party ceases trading or becomes insolvent; or
                                    <br/>- the other Party fails to abide by the duties as described in sections 3 and 4 above.

                                  <br/><br/>  10.3. &emsp;If this Agreement is terminated (for whatever reason), the Booking Platform’s right to sell the Operator’s product ends, but any rights or obligations of either Party in respect to bookings made before the termination date will continue until discharged.

                                        </li>

                                        <li>
                                          <h3> <b>   LIMITATION OF LIABILITY / FORCE MAJEURE </b> </h3> 

                                    11.1. &emsp;To the fullest extent permitted by law, Booking Platform shall be liable to the Operator, irrespective
                                    of the legal ground for damages, only to the extent that any such damages are caused by the gross negligence or the willful misconduct of Booking Platform. To the fullest extent permitted by law, the liability for slight negligence of Booking Platform shall be excluded.

                                   <br/><br/> 11.2. &emsp;Booking Platform shall not be liable for indirect damages, loss of profit, loss of interest, lost savings,
                                    consequential and pecuniary damages or damages resulting from third party claims.

                                  <br/><br/>  11.3. &emsp;Further, Booking Platform shall not be liable for any content provided by the Operator.

                                  <br/><br/>  11.4.&emsp;Notwithstanding section 11.1 and 11.2 neither Party shall have any liability under or to be deemed to be in breach of this Agreement for any delays or failures in performance of this Agreement, which result from unusual and unforeseeable circumstances beyond the reasonable control of that Party, the consequence of which neither Party could avoid even with all due care, including, but not limited to, war, threat of war, riot, civil strife, terrorist activity (actual or threatened), industrial dispute, unavoidable technical problems with transport, machinery or equipment, power failure, changes imposed by rescheduling or cancellation of flights by an airline, natural or nuclear disaster, fire, flood, drought, adverse weather conditions, epidemics or outbreaks of illness and level of water in rivers. If such circumstances continue for a continuous period of more than 3 months, either Party may terminate this Agreement by written notice to the other Party.

                                        </li>

                                            
                                        <li>
                                          <h3> <b>  GOVERNING LAW AND PLACE OF JURISDICTION </b> </h3> 

                                    This agreement will be subject to the governing law of Sri Lanka and Jurisdiction in Colombo 

                                        </li>

                                        <li>

                                          <h3> <b>  CONFIDENTIALITY </b> </h3> 

                                    All information, agreements and materials in relation to this Agreement are confidential and are not to be disclosed to any third party without the prior written consent of the other Party. No information disclosed in this Agreement is to be assumed to be of general knowledge in any market place.


                                     <br/><br/> Terms of this Agreement can only be disclosed: <br/>

                                      <ol type="a">
                                      <li>to the professional advisers of the Parties        </li>
                                      <li>where disclosure is required by law
                                                  </li>
                                      
                                        </ol>

                                    The termination of this Agreement does not release either Party from the confidentiality terms in relation to all information, agreements and materials documented in this Agreement.

                                        </li>


                                    <li>
                                      <h3> <b>  CONTACT DETAILS </b> </h3> 
                                       <br/> Booking Platform's contacts: <br/>

                                       <ul type="disc">
                                        <li>Aktivitar Finance Department, accounts@aktivitar.com</li>
                                        <li>Business Development & Booking Support, supplier@aktivitar.com</li>
                                       
                                      </ul>
                                    


                                    I, as the representative of the company accept and understand the terms and conditions of the agreement and bound to execute it. 
                                    </li>

                                    </ol>





              </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.props.Logout()} style={{ color:'orange'}}  color="primary">
            Disagree
          </Button>
          <Button onClick={()=>{this.props.AgreeToTerms()}} style={{ color:'orange'}}  color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
              </div>);
    }
    
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(agreement);
