export default function reducer(state={
    list:[],
    activeMessage:{
        created_at: "",
        description: "",
        id: 0,
        image_name: "",
        title: "",        
    },
    
},action){

    switch(action.type){
        case "STORE_ADMIN_MESSAGES":{
            return{...state,list:action.messages}
        }
        case "STORE_ACTIVE_MESSAGE":{
            return{...state,activeMessage:{
                ...state.activeDeal,
                id:action.deal.id,
                title:action.deal.title,
                description:action.deal.description,
                discount:action.deal.discount,
                image_name:action.deal.image_name,
                updated:true
            }}
        }
        case "LOCK_ACTIVE_DEAL":{
            return{...state,activeDeal:{
                ...state.activeDeal,                
                updated:false
            }}
        }
        case "STORE_ACTIVE_SUBSCRIPTION":{
            return{...state,activeSubscription:{
                ...state.activeSubscription,
                id:action.sub.id,
                deal_id:action.sub.deal_id,
                user_id:action.sub.user_id,
                from_date:action.sub.from_date,
                to_date:action.sub.to_date,
                activities:action.sub.activities,
                updated:true,
            }}
        } 
        case "LOCK_ACTIVE_SUBSCRITION":{
            return{...state,activeSubscription:{
                ...state.activeSubscription,                
                updated:false
            }}
        }        
    }

    return state;
}