import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import SearchSelect from '../Components/TagInput';

import BookingsView from '../Components/BookingsView';
import Paper from '@material-ui/core/Paper';
import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';

import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";

import { MyReviews } from '../Actions/ReviewsActions';
import Chip from '@material-ui/core/Chip';


import './../activity/css/style.css';
//import StarRatingComponent from 'react-star-rating-component';
//import Rating from '@material-ui/lab/Rating';
//import Rating from '@material-ui/lab/Rating';
//import Box from '@material-ui/core/Box';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});


const getStatus = (status) => {
  switch (status) {
    case 0: { return "Pending" }
    case 1: { return "Published" }
    case 2: { return "Un Published" }
    case 3: { return "Rejected" }    
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } }
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } }
    case 2: { return { backgroundColor: '#673AB7', color: '#FFF' } }
    case 3: { return { backgroundColor: '#263238', color: '#FFF' } }
    case 4: { return { backgroundColor: '#00695C', color: '#FFF' } }
    case 5: { return { backgroundColor: '#f44336', color: '#FFF' } }
  }
}

const StatusChip = (status) => {
  return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
}


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    reviews:state.Reviews.list,
    
  }
}


const mapDispatchToProps = dispatch => ({
 
  MyReviews:( suplr,sts )=>dispatch(MyReviews(suplr,sts )),

});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

class Tours extends Component {

  constructor(props) {
    super(props);

    
    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
      contact_name: null,
      supplier: null,
      status:null,
    };

    this.props.MyReviews(this.state.supplier,this.state.status);
    
    
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }
 
  handleChange = name => event => {

    console.log(name);
    this.setState({
      [name]: event.target.value,
    },()=>this.searchSupplier());

  };
 
    handleTabChange = (tab) => {
    this.setState({ value: tab});
  };


 

  selectSupplier = (value) => {
    if(value === null){
      this.setState({
        supplier:null
    },()=>this.searchSupplier());
    }else{
      this.setState({
        supplier:value.id
    },()=>this.searchSupplier());
    }
   
  }

  selectstatus = (e) =>{
    this.setState({
      status:e.target.value
    },()=>this.searchSupplier())
}


  searchSupplier=(x,y)=>{
    console.log(this.state.supplier);
    console.log(this.state.status);
    this.props.MyReviews(this.state.supplier,this.state.status);
}



//   selectst = (e) =>{
//     this.setState({
//       status:e.target.value
//     },()=>this.searchStatus())
// }

//   searchStatus=(value)=>{

//     this.props.MyReviews({
//       status: this.state.status,
     
//   });
// }

  // selectStatus = (value) => {
  //   if(value === null){
  //     this.setState({
  //       status:null
  //   },()=>this.searchStatus());
  //   }else{
  //     this.setState({
  //       status:value.id
  //   },()=>this.searchStatus());
  //   }
   
  // }
//   selectstatus = (e) =>{
//     this.setState({
//       id:e.target.value
//     },()=>this.searchReview())
// }



// searchReview = () =>{
//   this.props.MyReviews({
 
//     reviews:this.state.status
    
//   });
// }
 

  render() {

    const { classes } = this.props;

    

    return <Fragment>
   
    <AppBar title="Dashboard"></AppBar>
    
   
       <Drawer></Drawer>
      
       
      <div style={{width:'auto', marginLeft: drawerWidth+0, paddingTop:35, height:250 }}><div>

          
          <Table>
            
            <TableBody>
            <TableRow>
                    <TableCell style={{backgroundColor:'white', width:500,fontWeight:600, fontSize:16}}>Reviews & Ratings </TableCell>

            </TableRow> 

            </TableBody>
          </Table>
          <div className="tab-pan">
         <div className="tab-bok">





        <Table>
            <TableBody>
          <TableRow>
               
               
              
               
                <TableCell style={{backgroundColor:'#f2f2f2', width:700,fontWeight:500, paddingTop:25}} component="th">
                
                <Paper className={classes.root} elevation={1}  style={{marginBottom:30}}>


               



                 
                
                <div style={{ float:'right' }}>

                <table>
                <tr>
                <td>
                


                    </td>
                    <td>

                          <FormControl className={classes.formControl}>   
                          <InputLabel htmlFor="status-native-simple"> Status</InputLabel>
                          <Select
                            native
                            
                            onChange={this.selectstatus}
                            inputProps={{
                              name: 'status',
                              id: 'status-native-simple',
                            }}
                          >
                            <option value="" />
                            <option value="0"> Pending </option>
                            <option value="1"> Published</option>
                            <option value="2"> Unpublished</option>
                           
                            <option value="3"> Rejected</option>
                            
                          
                          </Select>
                      
                          </FormControl>  
                      </td>
                      </tr>
                      </table>

                </div>


                <Table className={classes.table}>
                
                   <table style={{width:'100%'}} >
                                  <thead style={{ fontSize:12}}>
                                      <tr>
                                        <th style={{width:"25%"}} className={classes.textAlignCenter}  >Activity Name </th>
                                        <th style={{width:"25%"}}> Supplier Name</th>
                                        <th style={{width:"15%"}}>Rating </th>
                                        <th style={{width:"15%"}}>Status</th>
                                        <th ></th>
                                      </tr>
                                      
                                  </thead>
                                  <tbody style={{ fontSize:12}}>
                                  {this.props.reviews.map(review => (
                                      <tr key={review.id}>
                                  <td className={classes.textAlignCenter} >{review.activity.title}</td>
                                  <td className={classes.textAlignCenter} >{review.activity.user.supplier_profile.work_name}</td>
                                  <td className={classes.textAlignCenter} ><StarRatingComponent
                          name="rate2"
                          editing={false}
                          renderStarIcon={() => <span></span>}
                          starCount={5}
                          value={review.review_rating}
                          renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal' }} >★</i>}
                        /></td>
                                  <td className={classes.textAlignCenter} >{StatusChip(review.status)}</td>
                                  <td className={classes.textAlignCenter} >
                                  <Button component={Link} to={'/ReviewView/'+review.activity.id}   variant="contained" style={{ background:"#f7a414", color:'white'}} className={classes.button}>
                                                <b>View</b> 
                                  </Button>
                                  </td> 
                                  </tr>
                                     ))}  
                                  </tbody>                    
                                </table> 







                </Table>
               
                
</Paper>


</TableCell>

            </TableRow>                           

            </TableBody>
        </Table>

        </div>
</div>
        </div>
      </div> 

</Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);