import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';

class StaticTextBox extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
        }
    }


    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }


    handleApply =()=>{

        if(this.props.required === true){
            if(this.props.value === null){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }

            if(this.props.value.length === 0){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }
        }


        this.setState({
            editMode:false
        });
    }

    render() {

    
        
        return <div>
            {this.state.editMode === false ? (<div className="supplier-block terms-conditions-details">
                { this.props.readOnly === false ? (<a onClick={()=>this.handleEditMode()} className="edit-block"></a>):null}
                <div className="block-content">
                    <div className="inner-block-content">
                        <div className="one-full-col ">
                            <div className="supply-col">
                                <label>{this.props.label}</label>
                                <div className="col-variable">
                                    <div className="inner-description">
                                        {this.props.seleted !== undefined &&  this.props.seleted !== ""  ? (<div>
                                            { this.props.seleted}<br/><br/>
                                        </div>) : null}
                                        
                                        {this.props.value}
                          </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>

            </div>
            ) : (<div className="supplier-block terms-conditions-details editable">               
                <div className="block-content">
                    <div className="inner-block-content">
                        <div className="one-full-col ">
                            <div className="supply-col">
                                <label>{this.props.label}</label>
                                <div className="col-variable">
                                    <div className="inner-description">
                                        
                                        <FormControl style={{ width: '100%', marginTop: 16 }} variant="outlined" >
                                            <TextField  onChange={this.props.onChange} value={this.props.value} variant="outlined" />
                                        </FormControl>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear"></div>
                        <br/>
                        <div className="button-container">
                                <a onClick={()=>this.handleApply()} className="button-apply">Apply</a> 
                        </div>
                    </div>
                </div>
                
            </div>)}
        </div>
    }
}

StaticTextBox.defaultProps = {
    readOnly:false
}

export default StaticTextBox;