import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PriceGroup from './PriceGroupRow';
import PriceGroupLocal from './PriceGroupRowLocal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';


const months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

});


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    locations: state.Locations.locations,
  }
}

const mapDispatchToProps = dispatch => ({

});
class SeasonPanel extends React.Component {


  constructor(props) {
    super(props);
  }


  state = {
    expanded: this.props.season.id === 0,

    pickuplocation: null,
    pickuptime: '00:00',
    pickupplace: '',


    a_from_pax: '',
    a_to_pax: '',
    a_selling_price: '',
    a_net_price: '',

    c_from_pax: '',
    c_to_pax: '',
    c_selling_price: '',
    c_net_price: '',

    i_from_pax: '',
    i_to_pax: '',
    I_selling_price: '',
    i_net_price: '',

    addAdultPrice:false,
    addChildrenPrice:false,
    addInfantPrice:false,

    nameEditMode:this.props.season.id === 0,
    fromDateEdit:this.props.season.id === 0,
    toDateEdit:this.props.season.id === 0

  };



  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handlePickupUpdate = (value, pIndex, field) => {
    this.props.updatePickupLocation(this.props.sIndex, this.props.lIndex, pIndex, field, value);
  }

  getNetPrice = (amount) => {
    return amount - (amount * (this.props.commission / 100));
  }

  

  handleTextChange = (e, field) => {
    this.setState({
      [field]: e.target.value
    });
  }

  

  addPickupPoint = () => {
    this.props.addPickup(this.props.sIndex, this.props.lIndex, {
      location: this.state.pickuplocation,
      pickup_time: this.state.pickuptime,
      description: this.state.pickupplace,
    });

    this.setState({
      pickuplocation: null,
      pickuptime: '',
      pickupplace: '',
    })
  }


  addAdultPriceGroup = (group) => {


    this.props.addPriceGroup(this.props.GroupIndex, this.props.SeasonIndex, group, {
      from_pax: this.state.a_from_pax,
      to_pax: this.state.a_to_pax,
      selling_price: this.state.a_selling_price,
      net_price: this.state.a_net_price
    });


    this.setState({
      a_from_pax: '',
      a_to_pax: '',
      a_selling_price: '',
      a_net_price: '',
      addAdultPrice:false
    });

  }

    addChildPriceGroup = (group) => {
      this.props.addPriceGroup(this.props.GroupIndex, this.props.SeasonIndex, group, {
        from_pax: this.state.c_from_pax,
        to_pax: this.state.c_to_pax,
        selling_price: this.state.c_selling_price,
        net_price: this.state.c_net_price
      });

      this.setState({
        c_from_pax: '',
        c_to_pax: '',
        c_selling_price: '',
        c_net_price: '',
        addChildrenPrice:false
      });

    }

      addInfantPriceGroup = (group) => {
        this.props.addPriceGroup(this.props.GroupIndex, this.props.SeasonIndex, group, {
          from_pax: this.state.i_from_pax,
          to_pax: this.state.i_to_pax,
          selling_price: this.state.i_selling_price,
          net_price: this.state.i_net_price
        });

        this.setState({
          i_from_pax: '',
          i_to_pax: '',
          i_selling_price: '',
          i_net_price: '',
          addInfantPrice:false
        });
  }

  render() {

    console.log("season Panel");
    console.log(this.props);
    const { classes } = this.props;
    const { expanded } = this.state;

    return (

      <ExpansionPanel style={{marginTop:10}} expanded={this.state.expanded} >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{backgroundColor:'#e2e2e2'}}
          onClick={()=>this.setState({expanded:!this.state.expanded})}
        >
           <table style={{width:'100%'}}>
          <tr>
            <td style={{width:'33.33%'}} ><Typography className={classes.heading} style={{fontWeight:500}}>{this.props.season.name}</Typography></td>
            <td style={{width:'33.33%'}} ><Typography className={classes.heading} style={{fontWeight:500}}>{new Date(this.props.season.from_date).getDate()}&nbsp;{months[new Date(this.props.season.from_date).getMonth()]}&nbsp;{new Date(this.props.season.from_date).getFullYear()} - {new Date(this.props.season.to_date).getDate()}&nbsp;{months[new Date(this.props.season.to_date).getMonth()]}&nbsp;{new Date(this.props.season.to_date).getFullYear()} </Typography></td>
            <td style={{width:'33.33%'}} ></td>
          </tr>
          </table>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{backgroundColor:'#f3f3f3'}} >
        <div class="pik-ret-main" style={{border:'none',width:'100%',marginTop:0}}>
      
        <div style={{border:'1px solid #9c9a9a', padding:10}}>
          <div className="availability-intro" style={{border:'none',padding:0,margin:0}}>
          <div className="avail-name" style={{width:'50%', float:'left'}}>
              <div className="supply-col" >
                <label>Season Name</label>
                <div className="col-variable">
                <FormControl>
                  <TextField
                    autoComplete='off'
                    id="outlined-name"                    
                    //className={classes.textField}
                     value={this.props.season.name}
                    onChange={(e)=>{ this.props.changeScheduleTitle(e.target.value,this.props.GroupIndex,this.props.SeasonIndex); }}                   
                    margin="normal"
                    variant="outlined"
                    type="text"
                    disabled={!this.state.nameEditMode}
                    InputProps={{
                      style:{backgroundColor: this.state.nameEditMode === false ? 'transparent':'#fff'}
                    }}
                    
                  />
                </FormControl>
                {this.state.nameEditMode === false ? (<IconButton size="small" onClick={()=>{this.setState({nameEditMode:true})}}  style={{marginLeft:5,marginTop:30}}>
                    <EditIcon/>
                </IconButton>):(<IconButton onClick={()=>{this.setState({nameEditMode:false})}} size="small"  style={{marginLeft:5,marginTop:30}}>
                    <CheckIcon/>
                </IconButton>)}
                
                
                
                </div>
              </div>  
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}> 
          <div className="avail-dates" style={{width:'50%', float:'left'}}>
              <div className="supply-col" style={{width:'50%', float:'left'}}>
                  <label>Valid From </label>
                  <div className="col-variable" >
                  <FormControl className={classes.formControl} >
                      <DatePicker value={new Date(this.props.season.from_date)}
                                  minDate={new Date()}
                                  autoOk={true}     
                                  format="dd/MM/yyyy"
                                  inputVariant="standard" 
                                  onChange={(e)=>{ this.props.changeDate(e,'from_date',this.props.GroupIndex,this.props.SeasonIndex); }}
                                  style={{width:100 }}                            
                                  margin="normal"                         
                                  disabled={!this.state.fromDateEdit} 
                                  InputProps={{
                                    style:{backgroundColor: this.state.fromDateEdit === false ? 'transparent':'#fff',padding:5}
                                  }}                         
                                  />
                  </FormControl>
                  {this.state.fromDateEdit === false ? (<IconButton size="small" onClick={()=>{this.setState({fromDateEdit:true})}}  style={{marginLeft:5,marginTop:30}}>
                    <EditIcon/>
                </IconButton>):(<IconButton onClick={()=>{this.setState({fromDateEdit:false})}} size="small"  style={{marginLeft:5,marginTop:30}}>
                    <CheckIcon/>
                </IconButton>)}
                  
                  </div>
              </div>
              <div className="supply-col" style={{width:'50%', float:'left'}}>
                  <label>Valid To </label>
                  <div className="col-variable">
                  <FormControl className={classes.formControl} >
                      <DatePicker value={new Date(this.props.season.to_date)}
                                  autoOk={true}
                                  minDate={new Date(this.props.season.from_date)}     
                                  format="dd/MM/yyyy"
                                  inputVariant="standard" 
                                  onChange={(e)=>{ this.props.changeDate(e,'to_date',this.props.GroupIndex,this.props.SeasonIndex); }}
                                  style={{width:100 }}                          
                                  margin="normal" 
                                  disabled={!this.state.toDateEdit}
                                  InputProps={{
                                    style:{backgroundColor: this.state.toDateEdit === false ? 'transparent':'#fff',padding:5}
                                  }}                                
                                  
                                  />
                  </FormControl>
                  {this.state.toDateEdit === false ? (<IconButton size="small" onClick={()=>{this.setState({toDateEdit:true})}}  style={{marginLeft:5,marginTop:30}}>
                    <EditIcon/>
                </IconButton>):(<IconButton onClick={()=>{this.setState({toDateEdit:false})}} size="small"  style={{marginLeft:5,marginTop:30}}>
                    <CheckIcon/>
                </IconButton>)}
                  </div>
              </div>

          </div>
        <div className="clear"></div>
        </MuiPickersUtilsProvider>
        </div>         

      </div>
    

      

      <div className="rates">
        <h4>Rates</h4>
        <div className="data-table-block" style={{boxShadow:'none'}}>
          {this.props.activity !== undefined && this.props.activity.include_adults === 1 ? (
          <div className="rates-adults rates-block">
            <h5>Adults</h5>
            <table className="data-table" cellspacing="0">
              <tr className="header-tr">
                <th className="rate-from">From</th>
                <th className="rate-to">To</th>
                <th className="rate-selling" style={{width:135}}>Selling Price</th>
                <th className="rate-net">NET</th>
                <th className="rate-net">&nbsp;</th>
              </tr>


              {this.props.season.adultPricing !== null ? this.props.season.adultPricing.map((rate, rIndex) => {


                return <Fragment>{this.props.rateChange==="INTERNATIONAL" ?<PriceGroup item={rate}
                group={'adultPricing'}
                sIndex={this.props.GroupIndex}
                lIndex={this.props.SeasonIndex}
                rIndex={rIndex}
                updatePricGroup={this.props.updatePricGroup}
                removePricGroup={this.props.removePricGroup} />:<PriceGroupLocal item={rate}
                group={'adultPricing'}
                sIndex={this.props.GroupIndex}
                lIndex={this.props.SeasonIndex}
                rIndex={rIndex}
                updatePricGroup={this.props.updatePricGroup}
                removePricGroup={this.props.removePricGroup} />}
                  </Fragment>



                }) : null}
                {this.state.addAdultPrice === true ? (<tr className="add-option">
                <td><input type="text" value={this.state.a_from_pax} onChange={(e) => this.handleTextChange(e, 'a_from_pax')} className="add-more" /></td>
                <td><input type="text" value={this.state.a_to_pax} onChange={(e) => this.handleTextChange(e, 'a_to_pax')} className="add-more" /></td>
                <td><input type="text" style={{width:135}} value={this.state.a_selling_price} onChange={(e) => this.handleTextChange(e, 'a_selling_price')} className="add-more" /></td>
                <td></td>
                <td><IconButton onClick={() => this.addAdultPriceGroup('adultPricing')} size="small"  >
                    <CheckIcon size="small" />
                </IconButton></td>
              </tr>):null}
                
              <tr >
              <td colspan={5} style={{textAlign:'center',padding:0,paddingTop:3}} >
                                  <div onClick={()=>this.setState({addAdultPrice:true})} className="group-add-button">
                                      <AddIcon style={{color:'#928d8d'}}/>
                                  </div>
                              </td>
                
              </tr>
              
            </table>
          </div>) : null}


          {this.props.activity !== undefined && this.props.activity.include_children === 1 ? (
            <div className="rates-child rates-block">
              <h5>Children</h5>
              <table className="data-table" cellspacing="0">
                <tr className="header-tr">
                  <th className="rate-from">From</th>
                  <th className="rate-to">To</th>
                  <th className="rate-selling" style={{width:135}}>Selling Price</th>
                  <th className="rate-net">NET</th>
                  <th className="rate-net">&nbsp;</th>
                </tr>
                {this.props.season.childrenPricing !== null ? this.props.season.childrenPricing.map((rate, rIndex) => {


                    return <Fragment>{this.props.rateChange==="INTERNATIONAL" ?<PriceGroup item={rate}
                    group={'childrenPricing'}
                    sIndex={this.props.GroupIndex}
                    lIndex={this.props.SeasonIndex}
                    rIndex={rIndex}
                    updatePricGroup={this.props.updatePricGroup}
                    removePricGroup={this.props.removePricGroup} />:<PriceGroupLocal item={rate}
                    group={'childrenPricing'}
                    sIndex={this.props.GroupIndex}
                    lIndex={this.props.SeasonIndex}
                    rIndex={rIndex}
                    updatePricGroup={this.props.updatePricGroup}
                    removePricGroup={this.props.removePricGroup} />}
                      </Fragment>



                    }) : null}
                
                {this.state.addChildrenPrice === true ? (
                <tr className="add-option">
                  <td><input type="text" value={this.state.c_from_pax} onChange={(e) => this.handleTextChange(e, 'c_from_pax')} className="add-more" /></td>
                  <td><input type="text" value={this.state.c_to_pax} onChange={(e) => this.handleTextChange(e, 'c_to_pax')} className="add-more" /></td>
                  <td><input type="text" style={{width:135}} valie={this.state.c_selling_price} onChange={(e) => this.handleTextChange(e, 'c_selling_price')} className="add-more" /></td>
                  <td></td>
                  <td><IconButton onClick={() => this.addChildPriceGroup('childrenPricing')} size="small"  >
                    <CheckIcon size="small" />
                </IconButton></td>
                </tr>):null}
                
              <tr >
              <td colspan={5} style={{textAlign:'center',padding:0,paddingTop:3}} >
                                  <div onClick={()=>this.setState({addChildrenPrice:true})} className="group-add-button">
                                      <AddIcon style={{color:'#928d8d'}}/>
                                  </div>
                              </td>
                
              </tr>   
                

              </table>

            </div>
          ) : null}


          {this.props.activity !== undefined && this.props.activity.include_infants === 1 ? (
            <div className="rates-child rates-block">
              <h5>Infants</h5>
              <table className="data-table" cellspacing="0">
                <tr className="header-tr">
                  <th className="rate-from">From</th>
                  <th className="rate-to">To</th>
                  <th className="rate-selling" style={{width:135}}>Selling Price</th>
                  <th className="rate-net">NET</th>
                  
                  <th className="rate-net">&nbsp;</th>
                </tr>
                {this.props.season.infantPricing !== null ? this.props.season.infantPricing.map((rate, rIndex) => {


                  return <Fragment>{this.props.rateChange==="INTERNATIONAL" ?<PriceGroup item={rate}
                  group={'infantPricing'}
                  sIndex={this.props.GroupIndex}
                  lIndex={this.props.SeasonIndex}
                  rIndex={rIndex}
                  updatePricGroup={this.props.updatePricGroup}
                  removePricGroup={this.props.removePricGroup} />:<PriceGroupLocal item={rate}
                  group={'infantPricing'}
                  sIndex={this.props.GroupIndex}
                  lIndex={this.props.SeasonIndex}
                  rIndex={rIndex}
                  updatePricGroup={this.props.updatePricGroup}
                  removePricGroup={this.props.removePricGroup} />}
                    </Fragment>



                  }) : null}
                
                {this.state.addInfantPrice === true ? (
                <tr className="add-option">
                <td><input type="text" value={this.state.i_from_pax} onChange={(e) => this.handleTextChange(e, 'i_from_pax')} className="add-more" /></td>
                <td><input type="text" value={this.state.i_to_pax} onChange={(e) => this.handleTextChange(e, 'i_to_pax')} className="add-more" /></td>
                <td><input type="text" style={{width:135}} value={this.state.i_selling_price} onChange={(e) => this.handleTextChange(e, 'i_selling_price')} className="add-more" /></td>
                <td></td>
                <td><IconButton onClick={() => this.addInfantPriceGroup('infantPricing')} size="small"  >
                    <CheckIcon size="small" />
                </IconButton></td>
              </tr>):null}
                
              <tr >
              <td colspan={5} style={{textAlign:'center',padding:0,paddingTop:3}} >
                                  <div onClick={()=>this.setState({addInfantPrice:true})} className="group-add-button">
                                      <AddIcon style={{color:'#928d8d'}}/>
                                  </div>
                              </td>
                
              </tr> 
                

              </table>

            </div>
          ) : null}
        </div>
      </div>
      {this.props.SeasonIndex !== 0 ? (<Fragment>
                  <div style={{ clear: 'both' }} ></div>
                  <div className="button-container">

                    <div style={{ float: 'right' }}><a onClick={() => this.props.deleteSeason(this.props.GroupIndex, this.props.SeasonIndex)} className="button-apply">Delete Season</a></div>
                    <div style={{ clear: 'both' }} ></div>
                  </div>
                </Fragment>) : null}            
    </div>
        </ExpansionPanelDetails>
        </ExpansionPanel>  
    
    
    


      
      

  
  );
  }
}

SeasonPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(SeasonPanel);