import { combineReducers } from 'redux';

import Auth from './AuthReducer';
import Common from './CommonReducer';
import Categories from './Category';
import Activities from './Activities';
import Account from './Account';
import Locations from './Locations';
import Deals from './Deals';
import Booking from './Booking';
import Reviews from './Reviews';
import AdminMessages from './AdminMessages';
import Finance from './Finance';

export default combineReducers({    
    Auth,
    Common,
    Categories,
    Activities,
    Account,
    Locations,
    Deals,
    Booking,
    Reviews,
    AdminMessages,
    Finance
   })