import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';


export function GetMessages(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/messages/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                    dispatch(storeMesages(json));                  
                    dispatch(endLoading());                                                      
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Error loading data','error'));
        });
        
    }
}


export function storeMesages(messages){
    return{type:'STORE_ADMIN_MESSAGES',messages:messages};
}

export function GetMessage(messageId){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/supplier/messages/get?id='+messageId,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
          }
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(storeActiveDeal(json));                      
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Error loading locations','error'));
      });
      
  }
}


export function storeActiveDeal(deal){
  return{type:'STORE_ACTIVE_MESSAGE',deal:deal};
}

export function LockActiveDeal(){
  return{type:'LOCK_ACTIVE_DEAL'};
}


export function UpdateMessage(data){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/supplier/messages/update',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
          },
          body:JSON.stringify(data),
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(GetMessages());
                  
                  if(data.d === 1){
                    dispatch(SnackBar(true,'Message deleted!','info'));
                  }
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Error loading locations','error'));
      });
      
  }
}






