import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';


import BookingsView from '../Components/BookingsView';
import Paper from '@material-ui/core/Paper';
import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';

import Typography from '@material-ui/core/Typography';

import { Divider } from '@material-ui/core';

import CssBaseline from "@material-ui/core/CssBaseline";


import { ActivityReviews } from '../Actions/ReviewsActions';

import ReviewBox from '../Components/ReviewBox'

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    activityReivews:state.Reviews.activityReivews,
  }
}


const mapDispatchToProps = dispatch => ({
  ActivityReviews:(aid)=>dispatch(ActivityReviews(aid)),
});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

class ReviewView extends Component {

  constructor(props) {
    super(props);

    const { aid } = this.props.match.params;

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
    };

    this.props.ActivityReviews(aid);
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  };

  render() {

    const { classes } = this.props;



    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>

        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: 'white', width: 800, fontWeight: 600, fontSize: 15 }}>Reviews & Ratings {this.props.activityReivews !== undefined && this.props.activityReivews !== null && this.props.activityReivews.length > 0 ? (' of '+ this.props.activityReivews[0].activity.title):null}</TableCell>
              { /* <TableCell style={{backgroundColor:'#e3f2fd', width:0,fontWeight:600, fontSize:15}}><Button style={{ width:150, marginLeft: 350 }} variant="contained" color="primary" component={Link} to="/Bookings" className={classes.button}>Back</Button></TableCell> */}



            </TableRow>

          </TableBody>
        </Table>

        <div style={{padding:15}} >
        {this.props.activityReivews.map((item)=>{
                return <ReviewBox onReject={this.props.RejectReview} onStatus={this.props.SetStatus} review = {item} />;
            })}
        </div>

      </div>
      </div>

    </Fragment>
  }
}

ReviewView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ReviewView);
