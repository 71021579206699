import React, { Props,Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'   
 
import { fetchUser, updateUser,uploadWorkLogo,uploadPersonalLogo,lockUserData,uploadDocument } from '../Actions/AuthActions';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

 
import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar'; 
import './../activity/css/style.css';



import {MyDashboard} from '../Actions/BookingActions';
import Authreduser from '../Reducers/AuthReducer';

const drawerWidth = 240;



const styles = theme => ({
    
    wrap:{
        width:480,
        margin:'auto',
        marginTop:100,
                
    },
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
      }, 
      toolbar: theme.mixins.toolbar,
  });

const createCard = (title,value) => {
  return <Card >
  <CardContent>
    <Typography  color="textSecondary" style={{textTransform:'uppercase'}} gutterBottom>
      {title}
    </Typography>
    <Typography variant="h2" style={{textAlign:'center'}} component="h2">
        {value}
    </Typography>
    
    </CardContent>
  <CardActions>
   
  </CardActions>
</Card>;    
}


const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        dashboard: state.Booking.dashboard,
        user : state.Auth.user,
        userDataOpen: state.Auth.dataopen,
    }
}

const mapDispatchToProps = dispatch => ({
  
  MyDashboard:()=>dispatch(MyDashboard()) ,
  fetchUser:()=>dispatch(fetchUser()),
  lockUserData:()=>dispatch(lockUserData())   
});

class Dashboard extends Component {

    constructor(props){
        super(props);

        this.state = {   
          
          id:0,
          name: null,
          user:[],
        };

        this.props.MyDashboard();
        this.props.fetchUser();
    }

    componentDidMount(){
      document.body.style.background = "#eee";
    }


    static getDerivedStateFromProps(props, state){



      if(props.userDataOpen){

          props.lockUserData();
          
          return {
            id: props.user.id,       
           
            contact_name: props.user.contact_name,
          
        }
      } 
      
      return null;

      
  }



    

    render(){

      const { classes } = this.props;
      

        return <Fragment>
                    <AppBar title="Dashboard"></AppBar>
                    
                   
                       <Drawer currentPage="dashboard"></Drawer>
                      
                       
                      <div style={{width:"fixed width", marginLeft : drawerWidth+15, paddingTop:35, height:250 }}>
                          {/* <table width="100%" >
                              <tr>
                                  <td width="33.33%" style={{padding:15}}>

                                  {createCard('Booked tours',this.props.dashboard.total_bookings)}


                                  </td>
                                  <td width="33.33%" style={{padding:15}}>
                                      {createCard('Upcoming tours',this.props.dashboard.total_upcoming)}
                                  </td>
                                  <td width="33.33%" style={{padding:15}}>
                                      {createCard('Ongoing tours',this.props.dashboard.total_ongoing)}
                                  </td>
                              </tr>
                              <tr>
                                  <td width="33.33%">&nbsp;</td>
                                  <td width="33.33%">&nbsp;</td>
                                  <td width="33.33%">&nbsp;</td>
                              </tr>
                              <tr>
                                  <td width="33.33%" style={{padding:15}}>

                                  {createCard('Completed tours',this.props.dashboard.completed)}


                                  </td>
                                  <td width="33.33%" style={{padding:15}}>
                                      {createCard('Total earnings (USD)',this.props.dashboard.total_earnings)}
                                  </td>
                                  <td width="33.33%" style={{padding:15}}>
                                      {createCard('Pending Payments (USD)',this.props.dashboard.total_pendings)}
                                  </td>
                              </tr>
                           </table>                         */}




      <div className="nip">
          <div className="dr-nam">
            <h1> Hi {this.state.contact_name}  </h1>
            {/* <span>Last Login 29 August 2019</span> */}
          </div>
          <div className="tol-ern">
            <span>Total earnings (USD)</span>
            <h4>$ 0.02</h4>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="acbup" width="100%">
          <div>
            <h5>Ongoing tours</h5>
            <span> {this.props.dashboard.total_ongoing}   </span> 
          </div>
          <div>
            <h5>Completed tours</h5>
            <span> {this.props.dashboard.completed}</span>
          </div>
          <div>
            <h5>Booked Tours</h5>
            <span>{this.props.dashboard.total_bookings}</span>
          </div>
          <div>
            <h5>Upcoming Tours</h5>
            <span>  {this.props.dashboard.total_upcoming}  </span>
          </div>
          <div>
            <h5>Pending Payments </h5>
            <span> {this.props.dashboard.total_pendings}</span>
          </div>
        </div>
        <div className="clearfix"></div>



                      </div>  


              </Fragment>
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Dashboard);