import React from 'react';
import PropTypes from 'prop-types';
import { withRouter} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';


import List from '@material-ui/core/List';


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Drawer from '@material-ui/core/Drawer';

import LoansIcon from '@material-ui/icons/Work';
import ReportIcon from '@material-ui/icons/LibraryBooks';
import AccountIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BookingIcon from '@material-ui/icons/CheckCircle';
import MessagesIcon from '@material-ui/icons/Mail';
import AdminIcon from '@material-ui/icons/ContactMail';
import FinancesIcon from '@material-ui/icons/AttachMoney';
import DealsIcon from '@material-ui/icons/LocalOffer';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import Badge from '@material-ui/core/Badge';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Logout } from '../../Actions/AuthActions';
import {Redirect} from 'react-router-dom'



const drawerWidth = 240;

const styles = theme => ({
    
    wrap:{
        width:480,
        margin:'auto',
        marginTop:100,
                
    },
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
      }, 
      toolbar: theme.mixins.toolbar,
  });

  const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        user : state.Auth.user 
    }
}

const mapDispatchToProps = dispatch => ({
  Logout:()=>dispatch(Logout())
});

class LeftMenu extends React.Component {

    constructor(props){
        super(props);

        this.state = {            
        };
    }
  

    gotoDashboard =()=>{
      this.props.history.push('/Dashboard');
    }    

  gotoBookings =()=>{
    this.props.history.push('/Bookings');
  }

  gotoTours = () =>{
    this.props.history.push('/Tours');
  }

  gotoMessages = () =>{
    this.props.history.push('/Messages');
  }

  gotoAccount = () =>{
    this.props.history.push('/Account');
  }

  gotoReviews = () =>{
    this.props.history.push('/Reviews');
  }

  gotoAdmin = () =>{
    this.props.history.push('/Admin');
  }

  gotoFinances = () =>{
    this.props.history.push('/Finances');
  }

  gotoDeals = () =>{
    this.props.history.push('/Deals');
  }

  logoutUser = () =>{    
    this.props.Logout();
  }

  render() {
  
    const { classes } = this.props;
           

    return (
      <div className={classes.root}>
        <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                      >
                        <div className={classes.toolbar} />
                        <List>
                            <ListItem button key="Dashboard" selected={this.props.currentPage === 'dashboard'} onClick={this.gotoDashboard}>
                              <ListItemIcon> <DashboardIcon /></ListItemIcon>
                              <ListItemText primary="Dashboard" />
                            </ListItem>
                            <ListItem button key="Bookings" selected={this.props.currentPage === 'booking'} onClick={this.gotoBookings}>
                              <ListItemIcon> <BookingIcon /></ListItemIcon>
                              <ListItemText primary="Bookings" />
                            </ListItem>
                            <ListItem button key="Tours" selected={this.props.currentPage === 'tours'} onClick={this.gotoTours}>
                              <ListItemIcon> <LoansIcon /></ListItemIcon>
                              <ListItemText primary="Tours & Activities" />
                            </ListItem>                            
                            <ListItem button key="Account" selected={this.props.currentPage === 'account'} onClick={this.gotoAccount}>
                            { this.props.user.rejected_fields.length > 0 ? (<Badge className={classes.padding} color="secondary" style={{position:'absolute', right:20}}  badgeContent={'!'}/>):null } 
                              <ListItemIcon> <AccountIcon /></ListItemIcon>
                              <ListItemText primary="My Account" />
                            </ListItem>
                            <ListItem button key="Reviews" selected={this.props.currentPage === 'reviews'} onClick={this.gotoReviews}>
                              <ListItemIcon> <ReportIcon /></ListItemIcon>
                              <ListItemText primary="Reviews & Ratings" />
                            </ListItem>
                            <ListItem button key="Admin" selected={this.props.currentPage === 'messages'} onClick={this.gotoAdmin}>
                              <ListItemIcon> <AdminIcon /></ListItemIcon>
                              <ListItemText primary="Admin Messages" />
                            </ListItem>
                            <ListItem button key="Finances" selected={this.props.currentPage === 'finance'} onClick={this.gotoFinances}>
                              <ListItemIcon> <FinancesIcon /></ListItemIcon>
                              <ListItemText primary="Finances" />
                            </ListItem>
                            <ListItem button key="Deals" selected={this.props.currentPage === 'deals'} onClick={this.gotoDeals}>
                              <ListItemIcon> <DealsIcon /></ListItemIcon>
                              <ListItemText primary="Deals & Discounts" />
                            </ListItem>
                            <ListItem button key="Logout"  onClick={this.logoutUser}>
                              <ListItemIcon> <LogoutIcon /></ListItemIcon>
                              <ListItemText primary="Logout" />
                            </ListItem>
                        </List>
                        
                      </Drawer>
                      {this.props.loggedIn ? null:<Redirect to="./Login"></Redirect>}
        
      </div>
    );
  }
}

LeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

LeftMenu.defaultProps = {
  currentPage: 'dashboard',
};

//export default withRouter(withStyles(styles)(LeftMenu));

export default compose(withRouter,connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(LeftMenu);