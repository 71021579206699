import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'scroll',
    maxHeight: 300,
    marginTop:30,
    marginBottom:10,

  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
});

function PinnedSubheaderList(props) {
  const { classes } = props;

  return (
    <List className={classes.root} subheader={<li />}>
           <ListItem style={{ backgroundColor: '#f9fbe7', width: 700 }} >
                          <Avatar><ImageIcon /></Avatar>
                          <ListItemText secondary="Hi, How are you?" />
                        </ListItem>


                        <ListItem style={{ backgroundColor: '#fff' }} >
                          <ListItemText style={{ display: 'flex', justifyContent: 'flex-end' }} secondary="I am fine. Thank you" />
                          <Avatar>
                            <ImageIcon />
                          </Avatar>
                        </ListItem>

                        <ListItem>

                          <ListItemText style={{ display: 'flex', justifyContent: 'flex-end', width: 300 }} secondary="How about you?" />
                          <Avatar>
                            <ImageIcon />
                          </Avatar>
                        </ListItem>


                        <ListItem>
                        <Avatar><ImageIcon /></Avatar>
                          <ListItemText secondary="Doing well bro." />
                        </ListItem>

                        <ListItem>
                        <Avatar><ImageIcon /></Avatar>
                          <ListItemText secondary="So so how the business" />
                        </ListItem>

                        
    </List>

    
  );
}

PinnedSubheaderList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PinnedSubheaderList);