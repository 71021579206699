import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, withStyles, theme } from '@material-ui/core/styles';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { SnackBar } from '../../Actions/CommonActions';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
  };
  
  const useStyles1 = theme => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: amber[700],
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: 6,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  });

  function MySnackbarContentWrapper(props) {
    
    const { parentProps, className, message, onClose, variant, ...other } = props;
    const { classes } = parentProps
    const Icon = variantIcon[variant];
    
    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  }
  
  MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  }; 





const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        showAlert : state.Common.showAlert,
        alertMessage : state.Common.alertMessage,
        alertVarient : state.Common.alertVarient,        
    }
}

const mapDispatchToProps = dispatch => ({
    SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
});

class Alert extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showAlert : true,
            alertMessage : null,
            alertVarient : 'info',
        };
    }

   

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }

    render() {

        return (
            <div>             
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                open={this.props.showAlert}
                autoHideDuration={4000}
                onClose={this.handleClose}
              >
                <MySnackbarContentWrapper
                  parentProps={this.props}  
                  onClose={this.handleClose}
                  variant={this.props.alertVarient}
                  message={this.props.alertMessage}
                />
              </Snackbar>
              </div>);
    }
    
}

Alert.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(useStyles1))(Alert);
