import React from 'react'
import {BrowserRouter, Route, Switch,Redirect} from 'react-router-dom'

import Login from '../Layouts/Login';
import Dashboard from '../Layouts/Dashboard';
import Account from '../Layouts/Account';
import Tours from '../Layouts/Tours';
import Messages from '../Layouts/Messages';
import Admin from '../Layouts/Admin';
import EditActivity from '../Layouts/EditActivity';
import Bookings from '../Layouts/Bookings';
import BookingDetails from '../Layouts/BookingDetails';
import Reviews from '../Layouts/Reviews';
import Finances from '../Layouts/Finances';
import Deals from '../Layouts/Deals';
import DealsDetails from '../Layouts/DealsDetails';
import ViewMessage from '../Layouts/ViewMessage';
import NewActivity from '../Layouts/NewActivity';
import Review_view from '../Layouts/ReviewView';

export default ()=>(
    <BrowserRouter basename="/supplier"> 
    <div>
        <Route path="/" exact component={Login}  />
        <Route path="/Login" exact component={Login}  />

        <Route path="/Dashboard" exact component={Dashboard}  />
        <Route path="/Account" exact component={Account}  />
        <Route path="/Tours" exact component={Tours}  />
        <Route path="/Messages" exact component={Messages}  />
        <Route path="/Admin" exact component={Admin}  />
        <Route path="/MessageView/:mid" exact component={ViewMessage}  />

        <Route path="/EditActivity" exact component={EditActivity}  />
        <Route path="/EditActivity/:aid" exact component={EditActivity}  />
        <Route path="/NewActivity" exact component={NewActivity}  />
        <Route path="/Bookings" exact component={Bookings}  />
        <Route path="/BookingDetails/:bid" exact component={BookingDetails}  />
        <Route path="/Reviews" exact component={Reviews}  />
        <Route path="/ReviewView/:aid" exact component={Review_view}  />
        <Route path="/Finances" exact component={Finances}  />
        <Route path="/Deals" exact component={Deals}  />
        <Route path="/DealsDetails/:did" exact component={DealsDetails}  />
    </div>
     </BrowserRouter>
);
