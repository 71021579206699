import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import BookingsView from '../Components/BookingsView';


import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';


import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { LogoutUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";

import { GetMessages, UpdateMessage } from '../Actions/AdminMessagesActions';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    deals: state.AdminMessages.list
  }
}



const mapDispatchToProps = dispatch => ({

  GetMessages: () => dispatch(GetMessages()),
  UpdateMessage: (data) => dispatch(UpdateMessage(data))
});



class Tours extends Component {

  constructor(props) {
    super(props);

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
    };

    this.props.GetMessages();
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  };

  onDelete = (mid) => {
    this.props.UpdateMessage({ mid: mid, r: 1, d: 1 });
  }

  render() {

    const { classes } = this.props;




    return <Fragment>

      <AppBar title="Dashboard"></AppBar>


      <Drawer currentPage="messages"></Drawer>


      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>


        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{backgroundColor:'#fff', width:500,fontWeight:600, fontSize:16}}>  

              <h3> Admin Messages  </h3>

              </TableCell>

            </TableRow>

          </TableBody>
        </Table>

        <div className="tab-pan" style={{marginTop:0}}>
          <div className="tab-bok">

            <table>
              <TableBody>
                <TableRow>




                  <td style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                    <Paper className={classes.root} elevation={1} style={{ marginBottom: 30,backgroundColor:'#fafbfb' }}>

                      <table style={{ textAlign: "right" }} className={classes.table} >

                      </table>


                      <table className={classes.table}>
                        <thead>
                          <tr style={{fontSize:16}}>
                            <th style={{ width: '60%' }}>Subject</th>
                            <th>Date</th>
                            {/* <td ><p style={{fontWeight:600, fontSize:15, color:'#000'}}>Status</p></td> */}
                            <th align="right"></th>
                            <th align="right"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.deals.map(row => (
                            <tr key={row.id} style={{fontSize:15}}>

                              <td style={{paddingTop:0,paddingBottom:0}} >
                                {row.title}
                              </td>

                              <td style={{paddingTop:0,paddingBottom:0}}>{row.created_at}</td>

                              {/* <td >{row.status}</td> */}
                              <td align="right" >
                                <Button variant="contained" color="primary" size="small" component={Link} to={"/MessageView/"+row.id}  className={classes.button} style={{ fontSize: 18, background: "#f7a414", textAlign: 'center' }}><b style={{fontWeight:500}} >View</b> </Button>
                             
                                <Button variant="contained" onClick={() => { this.onDelete(row.id) }} size="small" color="primary" className={classes.button} style={{ textAlign: "center", background: "#f7a414" }} >
                                  <b style={{fontWeight:500}}> Delete </b>
                                </Button>  
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div style={{ textAlign: 'right', backgroundColor: '#fff', height: '30' }} >
                        <CssBaseline />
                        {/* <Pagination
  limit={10}
  total={100}
/> */}
                      </div>
                    </Paper>

                  </td>

                </TableRow>

              </TableBody>
            </table>
          </div>
        </div>

      </div> </div>
    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);