import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


const styles = theme => ({
  card: {
    minWidth: 600,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
});

class RecipeReviewCard extends React.Component {

  taRef = React.createRef();
  chatBoxRef = React.createRef();

  constructor(props){
      super(props);

      this.state={
                    message:"",
                    visible_to:'3',
                    contactable:false
                 }
  }


 auto_grow = (element) => {
    this.taRef.current.style.height = "5px";
    this.taRef.current.style.height = (this.taRef.current.scrollHeight)+"px";
 }

 componentWillReceiveProps=(nextProps)=>{

      this.setState({
                contactable:nextProps.booking.contactable,
                visible_to: nextProps.booking.contactable === true ?'2':'3',
              });


  }

  componentDidUpdate = (nextProps,prevState) =>{
    this.chatBoxRef.current.scrollTop = this.chatBoxRef.current.scrollHeight;

    if(this.state.contactable !== this.props.booking.contactable  ){
        // this.setState({
        //     visible_to: this.props.booking.contactable === true ?'2':'3',
        //     statusUpdated:this.props.booking.contactable
        // })
    }
  }

  componentDidMount=()=>{
    this.auto_grow();


  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  onSend = () =>{
      this.props.onAdd(this.state.message,this.state.visible_to);
      this.setState({message:""});

  }

  handleChange = (event) => {
    this.setState({
      visible_to :event.target.value
    })
    ;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="col-l7">
			<div className="ct-div">
				<h4>Messaging History </h4>
				<div className="chat-box">
					<a >&nbsp;</a>
          <div className="chtbox-min" ref={this.chatBoxRef}>
          {this.props.messages.map((m)=>{
            return <div className={"cht "+(m.me === false ? 'rit':null)}>
                  <div>
                      {m.message}
                  <span>{(m.me === false ? ('on ' + m.created_at+' from '+m.from):<p>on {m.created_at} to {m.to}</p>)} </span>
                  </div>
                </div>
        })}
</div>




					<div className="frm">
            <tr>
              <td style={{verticalAlign:'middle'}}><span > Send message to </span></td>
              <td><FormControl component="fieldset" className={classes.formControl}>

       <RadioGroup
         aria-label="visible"
         name="gender2"
         className={classes.group}
         value={this.state.visible_to}
         onChange={this.handleChange}
         row
       >


        { this.state.contactable ? (<FormControlLabel
          value="2"
          control={<Radio color="primary" style={{color:"#f7a414" }} />}
          label="Client"
          labelPlacement="start"
        />):null }
         <FormControlLabel
           value="3"
           control={<Radio color="primary" style={{color:"#f7a414" }} />}
           label="Admin"
           labelPlacement="start"
         />

       </RadioGroup>

     </FormControl></td>
            </tr>
						<p> </p>
						<form>
							<textarea ref={this.taRef} onChange={(e)=>{ this.setState({message:e.target.value},()=>this.auto_grow()) }} value={this.state.message} placeholder="Type your message"></textarea>
							<input type="button" onClick={()=>{ this.onSend(); }} value="" name="" />
						</form>
					</div>
					<div className="clear"></div>
				</div>
			</div>
		</div>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
