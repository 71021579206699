export function startLoading(){ 
    return{type:'LOADIND_START'};
}

export function endLoading(){ 
    return{type:'LOADIND_END'};
}

export function SnackBar(show,message,varient){ 
    return {
                type:'SHOW_ALERT',
                show : show,
                message : message,
                varient:varient,                
           } ;

}

export function handleHTTPError(response){
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;    
}

