import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';

class LocationRowLocal extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
        }
    }


    handleEditMode = () => {
        this.setState({
            editMode: true
        });
    }


    handleApply = () => {

        if (this.props.required === true) {
            if (this.props.value === null) {
                this.props.onError(true, this.props.label + " can not be empty", 'error');
                return false;
            }

            if (this.props.value.length === 0) {
                this.props.onError(true, this.props.label + " can not be empty", 'error');
                return false;
            }
        }


        this.setState({
            editMode: false
        });
    }

    render() {
        console.log(this.props)
        console.log("priceGroupRowLOcal")


        return <Fragment>
            {this.state.editMode === false ? (
            
                    <tr className={this.props.rIndex % 2 !== 0 ? 'odd':"even"}>
                        <td>{this.props.item.from_pax}</td>
                        <td>{this.props.item.to_pax}</td>
                        <td>LKR {this.props.item.selling_price}</td>
                        <td>LKR {this.props.item.net_price}</td>
                        <td className="row-controls">
                            <a style={{cursor:'pointer'}} onClick={()=>this.handleEditMode()} class="edit-row"></a>
                            <a style={{cursor:'pointer'}} onClick={()=>this.props.removePricGroup(this.props.sIndex,this.props.lIndex,this.props.group,this.props.rIndex)} class="delete-row"></a>
                        </td>
                    </tr>
                ) : (<tr className="add-option">
                    <td><input type="text" value={this.props.item.from_pax} className="add-more" onChange={(e)=>{this.props.updatePricGroup(this.props.sIndex,this.props.lIndex,this.props.group,this.props.rIndex,'from_pax',e.target.value)}} /></td>
                    <td><input type="text" value={this.props.item.to_pax} className="add-more" onChange={(e)=>{this.props.updatePricGroup(this.props.sIndex,this.props.lIndex,this.props.group,this.props.rIndex,'to_pax',e.target.value)}} /></td>
                    <td><input type="text" value={this.props.item.selling_price} className="add-more" onChange={(e)=>{this.props.updatePricGroup(this.props.sIndex,this.props.lIndex,this.props.group,this.props.rIndex,'selling_price',e.target.value)}} /></td>
                    <td>LKR {this.props.item.net_price}</td>
                    <td><IconButton onClick={()=>this.setState({editMode:false})} size="small"  >
                    <CheckIcon size="small" />
                </IconButton></td>
                </tr>)}
        </Fragment>
    }
}

export default LocationRowLocal;