import React, { Props, Component, Fragment } from 'react';
import { fetchLocations } from '../Actions/LocationAction';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import MessagesPanel from '../Components/MessagesPanel';

import { MyReviews } from '../Actions/ReviewsActions';
import Paper from '@material-ui/core/Paper';
import { MyBookings } from '../Actions/BookingActions';
import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';




import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';


import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';


import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider, Chip } from '@material-ui/core';



import { fetchUser, updateUser, uploadWorkLogo, uploadPersonalLogo, lockUserData, uploadDocument } from '../Actions/AuthActions';
import { GetBooking, UpdateBookingStatus, AddMessage,SetAmmendmentStatus,SetAmmendmentAcknowlage } from '../Actions/BookingActions';
import { th } from 'date-fns/esm/locale';

import './../activity/css/supplier-ui-new.css';
import './../activity/css/stylenew.css';
import EXpanal from '../Components/ExpanisionPanal';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    activeBooking: state.Booking.activeBooking,
    user: state.Auth.user,
    userDataOpen: state.Auth.dataopen,
    BookingList: state.Booking.list,
    reviews: state.Reviews.list,
  }
}




const mapDispatchToProps = dispatch => ({

  GetBooking: (bid) => dispatch(GetBooking(bid)),
  UpdateBookingStatus: (bid, status) => dispatch(UpdateBookingStatus(bid, status)),
  AddMessage: (bid, message, visible) => dispatch(AddMessage(bid, message, visible)),
  fetchUser: () => dispatch(fetchUser()),
  lockUserData: () => dispatch(lockUserData()),
  MyBookings: () => dispatch(MyBookings()),
  MyReviews: () => dispatch(MyReviews()),
  fetchLocations: () => dispatch(fetchLocations()),
  SetAmmendmentStatus:(bookingId,ammendmentId,status)=>dispatch(SetAmmendmentStatus(bookingId,ammendmentId,status)),
  SetAmmendmentAcknowlage:(bookingId,ammendmentId,status)=>dispatch(SetAmmendmentAcknowlage(bookingId,ammendmentId,status))
});


const getStatus = (status) => {
  switch (status) {
    case 0: { return "Pending" }
    case 1: { return "Confirmed" }
    case 2: { return "Rejected" }
    case 3: { return "Canceled" }
    case 4: { return "Completed" }
    case 5: { return "Dispute" }
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
    case 2: { return { backgroundColor: '#673AB7', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
    case 3: { return { backgroundColor: '#263238', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
    case 4: { return { backgroundColor: '#00695C', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
    case 5: { return { backgroundColor: '#f44336', color: '#FFF', borderRight: 'none', borderTop: 'none', padding: 0, paddingLeft: 8, paddintRight: 8, textAlign: 'center' } }
  }
}

const StatusChip = (status) => {
  return <Fragment><Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip></Fragment>
}


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

class Tours extends Component {

  constructor(props) {
    super(props);

    var { bid } = this.props.match.params;


    this.state = {
      age: '',

      labelWidth: 0,
      id: 0,
      name: null,
      email: '',
      phone: '',
      bookingStatus: '',
      bookingReff: '',
      bookingTimestamp: '',
      tourDate: '',
      pickupPlace: '',
      pickupTime: '',
      remarks: '',
      user: [],
    };
    this.props.fetchUser();
    this.props.fetchLocations();

    if (bid !== null) {
      this.props.GetBooking(bid);

    }
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  };

  getStatus(status) {
    switch (status) {
      case 0: { return "Pending" }
      case 1: { return "Confirmed" }
      case 2: { return "Rejected" }
      case 3: { return "Canceled" }
      case 4: { return "Completed" }
      case 5: { return "Dispute" }
    }
  }

  getDuration(measure) {
    switch (measure) {
      case 1: { return "Days" }
      case 2: { return "Hours" }
      case 3: { return "Minutes" }
    }
  }



  changeStaus = (status) => {
    this.props.UpdateBookingStatus(this.props.activeBooking.id, status);
  }

  addMessage = (message, visible_to) => {
    this.props.AddMessage(this.props.activeBooking.id, message, visible_to);
  }

  getTripType(type) {

    switch (type) {
      case 1: { return "Round tour" }
      case 2: { return "Day Tour" }
      case 3: { return "Activity" }
    }
  }


  static getDerivedStateFromProps(props, state) {



    if (props.userDataOpen) {

      props.lockUserData();

      return {
        id: props.user.id,
        email: props.user.email,
        contact_name: props.user.contact_name,
        contact_mobile_phone: props.user.contact_mobile_phone,
        bookingStatus: props.user.status


      }
    }

    return null;


  }





  render() {

    const { classes } = this.props;


    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer currentPage="booking"></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>



        <Table>
          <TableBody>
            <TableRow>

              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">



                <div id="supplier-main-block">
                  <div class="set-cont">
                    <div style={{ marginTop: 25 }}>


                      <div class="bk-deit" style={{marginBottom:20}}>
                        <div class="bk-img">

                          <img src={this.props.activeBooking.activity.cover_image} alt="" />
                        </div>
                        <div class="bk-cont">
                          <div class="bk-hed">
                            <h4>{this.props.activeBooking.activity.title} </h4>
                          </div>
                          <div class="bk-typs">
                            <p class="ty">Tour type <span>{this.getTripType(this.props.activeBooking.activity.tour_type)}</span></p>

                            {/* <p class="pc">Rate <span>

                  <StarRatingComponent
                              name="rate2"
                              style={{marginTop:5}}
                              editing={false}
                              renderStarIcon={() => <span></span>}
                              starCount={5}
                              value={this.props.reviews.review_rating}
                              renderStarIcon={() => <i style={{ fontSize: 10, fontStyle: 'small' }} >★</i>}
                            />

                  </span></p> */}




                          </div>


                          <div class="bk-tb">
                            <div class="duration">
                              <span>Duration</span>
                              {this.props.activeBooking.activity.duration_value}
                              {this.getDuration(this.props.activeBooking.activity.duration)}
                            </div>
                            <div class="pax">
                              <span>No of PAX</span>
                              {this.props.activeBooking.num_adults}A/{this.props.activeBooking.num_children}C/{this.props.activeBooking.num_infants}I
              </div>
                            <div class="location">
                              <span>Location </span>
                              {this.props.activeBooking.pickup !== null ? this.props.activeBooking.pickup.location.name : null}
                            </div>
                            <div class="rate" >
                              <span>Payment (USD) </span>
                              <h4>{this.props.activeBooking.booking_amount}</h4>

                              {/* <a href="">     </a>   */}
                              {/* <Chip style={{ size:'small'}} label={this.getStatus(this.props.activeBooking.status)} />  */}
                            </div>
                          </div>
                        </div>
                        <div class="clear"></div>
                      </div>

                      {this.props.activeBooking.activity.sale_type === 2 && this.props.activeBooking.status !== 5 ? (
                        <div style={{ marginBottom:'8px'}}>
                        <Button onClick={() => { this.changeStaus(1) }} style={{ background: "#f7a414" , width: 150, marginLeft: 0 }} variant="contained" color="primary" className={classes.button}>Accept</Button>
                        <Button onClick={() => { this.changeStaus(2) }} style={{ background: "#f7a414" , width: 150, marginLeft: 0 }} variant="contained" color="primary" className={classes.button}>Reject</Button>
                     </div>
                      ):null}


                      {this.props.activeBooking.ammendments.length > 0 ? (<div className="bd">
							<h4>Booking Amendments</h4>
							<div className="bk-deit ">
								<table className="upcomeing-tbl upc booking-ammends" style={{width:'100%'}}>
									<thead>
										<tr>
											<th className="tc" width="12.5%">Pickup Date</th>
											<th className="tc" width="12%">Location</th>
											<th className="tc" width="10%">No of Pax</th>
											<th className="tc" width="14.5%" >Amount</th>
											<th className="tc" width="14.5%" >Balance</th>
											<th className="tc" width="14.5%" >Status</th>
											<th width="12.5%" ></th>
										</tr>
									</thead>
									<tbody>
										{ this.props.activeBooking.ammendments.map((data,index)=>{
											return <tr>
																<td className="tc" >{data.formatted_pickup_date}</td>
																<td className="tc" >{data.pickup_info.location.name}</td>
																<td className="tc" >{data.num_adults}A/{data.num_children}C/{data.num_infants}I</td>
																<td className="tc"  >{data.ammend_amount}</td>
																<td className="tc"  >{data.balance_amount}</td>
																<td className="tc"  >{StatusChip(data.status)}</td>
																<td width="14%" >{data.supplier_acknowledged === 1 ? (<Button size="small" onClick={()=>this.props.SetAmmendmentAcknowlage(this.props.activeBooking.id,data.id,1)} style={{ background: "#f7a414" }} >Acknowledge</Button>):null}{data.status === 0 && this.props.activeBooking.activity.sale_type === 2  ? (<Button size="small" onClick={()=>this.props.SetAmmendmentStatus(this.props.activeBooking.id,data.id,1)} style={{ background: "#f7a414" }} >Confirm</Button>):null}&nbsp;{data.status === 0 && this.props.activeBooking.activity.sale_type === 2 ? (<Button size="small" onClick={()=>this.props.SetAmmendmentStatus(this.props.activeBooking.id,data.id,2)} style={{ background: "#f7a414"  }} >Reject</Button>):null}</td>
															</tr>;
										}) }

									</tbody>
								</table>
							</div>
						</div>):null}

                      <div class="row">
                        <div class="col-l5">
                          <div class="bd">
                            <h4>Booking Details</h4>
                            <div>
                              <p>Status<span>  {StatusChip(this.props.activeBooking.status)}   </span></p>
                              <p>Lead Traveler<span>  {this.props.activeBooking.travellers[0] !== undefined ? this.props.activeBooking.travellers[0].firstname : null} {this.props.activeBooking.travellers[0] !== undefined ? this.props.activeBooking.travellers[0].lastname : null} </span></p>
                              {/*<p>Email<span>{this.state.email}</span></p>*/}
                              <p>Contact Number<span> {this.props.activeBooking.travellers[0] !== undefined ? this.props.activeBooking.travellers[0].phone : null}   </span></p>
                              {/* <p>Booking Status <span class="bk"> {this.getStatus(this.props.activeBooking.status) }</span></p> */}
                              <p>Booking Reference<span>{this.props.activeBooking.booking_reference}</span></p>
                              <p>Booking timestamp <span>{this.props.activeBooking.created_at}</span></p>
                              <p>Tour Date <span>{this.props.activeBooking.start_date}</span></p>
                              <p>Pickup Address<span> {this.props.activeBooking.hotel_address}  </span></p>
                              <p>Pickup Time<span>{this.props.activeBooking.pickup !== null ? this.props.activeBooking.pickup.pickup_time : null}</span></p>
                              <p>Net Price <span>USD {this.props.activeBooking.net_price}</span></p>
                              <p>Product Code <span>{this.props.activeBooking.activity.tour_id} </span></p>
                              <p>Remarks</p>
                              <div>{this.props.activeBooking.remarks}</div>
                            </div>
                          </div>
                        </div>
                        <MessagesPanel onAdd={this.addMessage} messages={this.props.activeBooking.messages} booking={this.props.activeBooking} />
                        <div class="clear"></div>
                      </div>




                      <div class="row-pi">
                        <div class="col-12">
                          <div class="bd">
                            <h4>Product Information</h4>
                          </div>
                          {/* <div class="pro-info">
				<div class="active">

				</div>
			<div class="active">



      </div>





			</div> */}
                        </div>
                        <EXpanal title="Overview" type="overview" content={this.props.activeBooking.snap_overview}></EXpanal>
                        {/* <EXpanal title="Highlight"></EXpanal> */}
                        <EXpanal title="Itinarary" type="itinery" content={this.props.activeBooking.snap_Itinerary}></EXpanal>
                        <EXpanal title="Inclusions" type="inclusions" content={this.props.activeBooking.snap_inclusions}></EXpanal>
                        <EXpanal title="Exclusions" type="exclusions" content={this.props.activeBooking.snap_exclusions}></EXpanal>
                        <EXpanal title="Know before you go" type="kbyg" content={this.props.activeBooking.snap_kbyg}></EXpanal>
                        <EXpanal title="Know before you book" type="kbyb" content={this.props.activeBooking.snap_kbyb}></EXpanal>
                        <EXpanal title="Cancellation Policy" type="cancel" content={this.props.activeBooking.snap_cancel_policy} ></EXpanal>
                      </div>








                    </div>





                  </div>






                </div>


















              </TableCell>
            </TableRow>
          </TableBody>
          <br />
        </Table>

      </div>
      </div>

    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);
