import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import MessagesPanel from '../Components/MessagesPanel';
import BookingsView from '../Components/BookingsView';
import DateAndTimePickers from '../Components/DateAndTimePickers';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';


import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';


import { GetDeal,LockActiveDeal,GetSubscription,LockActiveSubscrption,UpdateSubscription } from '../Actions/DealsActions';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});




const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    activeDeal:state.Deals.activeDeal,
    activeSubscription:state.Deals.activeSubscription
  }
}

let id = 0;
function createData(name, type, duration, location, status, actions) {
  id += 1;
  return { id, name, type, duration, location, status, actions };
}


const mapDispatchToProps = dispatch => ({

  GetDeal:(did)=>dispatch(GetDeal(did)),
  LockActiveDeal:()=>dispatch(LockActiveDeal()),
  GetSubscription:(did)=>dispatch(GetSubscription(did)),
  LockActiveSubscrption:()=>dispatch(LockActiveSubscrption()),
  UpdateSubscription:(data)=>dispatch(UpdateSubscription(data))
});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});


  

class CheckboxListSecondary extends React.Component {

  constructor(props) {
    super(props);

    var {did} = this.props.match.params;

    this.state = {      
      id:0,
      title:"",
      description:"",
      discount:null,
      image_name:null,
      activeSubscription: this.props.activeSubscription
     
    };

    if(did !== undefined && did !== null){
      this.props.GetDeal(did);
      this.props.GetSubscription(did);
    }

   
  }

  static getDerivedStateFromProps(props, state) {


    
    if (props.activeDeal.updated) {
      
      props.LockActiveDeal();
      return {
        id:props.activeDeal.id,
        title:props.activeDeal.title,
        description:props.activeDeal.description,
        discount:props.activeDeal.discount,
        image_name:props.activeDeal.image_name,
      }
    }

    if (props.activeSubscription.updated) {
      
      props.LockActiveSubscrption();
      return {
        id:props.activeDeal.id,
        title:props.activeDeal.title,
        description:props.activeDeal.description,
        discount:props.activeDeal.discount,
        image_name:props.activeDeal.image_name,
        activeSubscription:props.activeSubscription
      }
    }

    return null;


  }

  

  handleSubChange = (e,index) => {
    const item = e.target.id;
    const isChecked = e.target.checked;
    this.setState({
        ...this.state,
        activeSubscription:{
            ...this.state.activeSubscription,
            activities:Object.values({
              ...this.state.activeSubscription.activities,
              [index]:{
                ...this.state.activeSubscription.activities[index],
                checked: isChecked
              } 
            })
            
          }
    });
    

  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });

  };

  changeDate = (value,field) =>{
    this.setState({
      ...this.state,
      activeSubscription:{
          ...this.state.activeSubscription,
          [field]:value          
        }
  });
  }

  Unsubscribe = () =>{
    this.setState({
      ...this.state,
      activeSubscription:{
          ...this.state.activeSubscription,
          subbed:false          
        }
  },()=>this.props.UpdateSubscription(this.state.activeSubscription));
  }

  onUpdateSubscription = () =>{
    this.setState({
      ...this.state,
      activeSubscription:{
          ...this.state.activeSubscription,
          subbed:true          
        }
  },()=>this.props.UpdateSubscription(this.state.activeSubscription))

      
  }

  render() {

    const { classes } = this.props;

    const rows = [
      createData('JM Bairstow', 10, 6.0, 4, 1),
      createData('AE Jones', 15, 3.0, 1, 2.0),
      createData('AD Hales', 17, 2.0, 5, 4.0),
      createData('SR Taylor', 19, 4.0, 13, 3.0),
      createData('JE Root', 25, 5.0, 2, 3.0),

    ];

    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [65, 59, 80, 81, 56, 55, 40]
        }
      ]
    };


    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer currentPage="deals"></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>

      <Table>

          <TableBody>
            <TableRow>
                  
                  <TableCell style={{backgroundColor:'#fff', width:500,fontWeight:600, fontSize:16}}> 
                        
                                <h3> Deals & Discount Description </h3>
                  </TableCell>
                  
                  <TableCell style={{backgroundColor:'#fff',fontWeight:600, fontSize:15}}><Button style={{ width:150, marginLeft: 350,background:"#f7a414" }} variant="contained" color="primary"  component={Link} to="/Deals" className={classes.button}>Back</Button></TableCell> 

                         

            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>

              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">

                <Paper className={classes.root} elevation={1} style={{ marginBottom: 0 }}>
                  <table width="100%">
                      <tr>
                          <td style={{verticalAlign:'top', width:'50%'}}>
                              <Typography style={{ fontWeight: 600, fontSize: 17, marginBottom: 10, color:'black' }} color="primary"> {this.state.title} &nbsp; ({this.state.discount}%)</Typography>
                              <Typography style={{ fontWeight: 400, fontSize: 15, marginBottom: 10 }} color="textSecondary" >{this.state.description}</Typography>
                          </td>
                          <td style={{verticalAlign:'top', padding:15}}>
                             
                          </td>
                      </tr>
                  </table>
                
                <Divider/>
               
        <br />
        <div style={{ fontWeight: 500, width: 600, fontSize: 13 }}>            
            <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>           
            <FormControl className={classes.formControl} >
                <DatePicker value={new Date(this.state.activeSubscription.from_date)}
                            autoOk={true}     
                            format="dd/MM/yyyy"
                            inputVariant="standard" 
                            onChange={(e)=>{ this.changeDate(e,'from_date'); }}
                            style={{width:100 }}                             
                            margin="normal" label="Start Date"
                            
                            
                            
                            />
            </FormControl>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <FormControl className={classes.formControl} >
                <DatePicker value={new Date(this.state.activeSubscription.to_date)}
                            autoOk={true}     
                            format="dd/MM/yyyy"
                            inputVariant="standard" 
                            onChange={(e)=>{ this.changeDate(e,'to_date'); }}
                            style={{width:100 }}                             
                            margin="normal" label="End Date"
                            
                            
                            
                            />
            </FormControl>
            </MuiPickersUtilsProvider>
            </div>
            <br />
          </div>
                       
                        <br />
                  
                        <div>
            <p style={{ fontWeight: 800, width: 400, fontSize: 15, paddingTop: 1 }}>My Tours & Activities</p>
           
          </div>
    <div>
    <ul dense className={classes.root} style={{ width:'100%', marginLeft: 0 }} >
        {this.state.activeSubscription.activities.map((value,index) => (
          <li style={{width:'50%',display:'block',height:30,float:'left'}}>
            <div>
              <div style={{float:'left',margin:2}}><Checkbox
                  checked ={value.checked}
                  onChange={(e)=>{ this.handleSubChange(e,index)}}
                  color="primary"
                  style={{ color:'orange'}}
              />
              </div>
                <div style={{float:'left',margin:2,marginTop:12}} >{value.label}</div>
                
            </div> 
          </li>
          
        ))}
      </ul>
      <div style={{clear:'both'}}/>
    </div>
    <br/>
    <Divider/>
<div style={{ textAlign: 'right', backgroundColor:'#fff', height:'30', marginTop:10 }} >
{ this.state.activeSubscription.subbed === 1 ? <Button onClick={()=>this.Unsubscribe()} style={{ width:150, marginLeft: 350,background:"#f7a414" }} variant="contained" color="primary"  className={classes.button}>Unsubscribe</Button> : <Button onClick={this.onUpdateSubscription} style={{ width:150, background:"#f7a414" }} variant="contained" color="primary"  className={classes.button}>Subscribe</Button> }
  &nbsp;

             
</div>

                </Paper>

              </TableCell>
            </TableRow>
          </TableBody>
          <br />
        </Table>
        
      </div>
      </div>

    </Fragment>
  }
}

CheckboxListSecondary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(CheckboxListSecondary);