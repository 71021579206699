export default function reducer(state={
    profile: {
                    id: 0,
                    name: null,
                    email: null,
                    user_type: 2,
                    status: 0,
                    work_name: null,
                    work_address: null,
                    work_reg_number: null,
                    work_fix_phone: null,
                    work_mobile: null,
                    work_avatar: null,
                    contact_name: null,
                    contact_NIC: null,
                    contact_home_phone: null,
                    contact_mobile_phone: null,
                    contact_address: null,
                    contact_avatar: null,
                    categories: new Map(),
                    work_name_review:false,
                    work_address_review:false,
                    work_reg_number_review:false,
                    work_fix_phone_review:false,
                    work_mobile_review:false,
                    contact_name_review:false,
                    contact_NIC_review:false,
                    contact_home_phone_review:false,
                    contact_mobile_phone_review:false,
                    contact_address_review:false,
                    bank_name_review:false,
                    bank_branch_review:false,
                    bank_account_review:false,
                    bank_account_name_review:false,
                    work_logo_review:false,
                    contact_logo_review:false,
                    br_copy_review:false,
                    tb_licence_copy_review:false,
                    ins_copy_review:false,
                    billing_proof_review:false,
                    email_verified:0,
                    rejected_fields:[],
                    in_review_fields:[]


                },    
},action){
switch(action.type){
    case "STORE_ACCOUNT":{
        return{...state,profile:action.account}
    }    
}
return state
}