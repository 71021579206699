export default function reducer(state={
    categories:[]
},action){

    switch(action.type){
        case "STORE_CATEGORIES":{
            return{...state,categories:action.categories}
        }         
    }

    return state;
}