import React, { Props,Component, Fragment} from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import BookingsView from '../Components/BookingsView';

 
import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';


import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar'; 


import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import {Redirect} from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";

import { GetDeals } from '../Actions/DealsActions';
import Chip from '@material-ui/core/Chip';

const drawerWidth = 240; 

const styles = theme => ({
 
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap:{
    width:480,
    margin:'auto',
    marginTop:100,
            
},
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
      }, 

      logoWrap:{
        textAlign:'center',
      }, 
      toolbar: theme.mixins.toolbar,
  });

  
  const getStatus = (status) =>{        
    switch(status){
      // case 0 :{ return "Pending" }
      case 0 :{ return "Ongoing" }
      case 3:{ return "Active" }
      case 1 :{ return "Promo Ended" }    
    }
  }
  
   const StatusStyle = (status) => {
    switch(status){
      case 0 :{ return {backgroundColor:'#90A4AE', color:'#FFF'} }
      case 3:{ return {backgroundColor:'#4CAF50', color:'#FFF'} }
      case 1 :{ return {backgroundColor:'#f44336', color:'#FFF'} }   
    }
   }
  
   const StatusChip = (status) =>{
        return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
   }

const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn, 
        deals:state.Deals.list 
    }
}



const mapDispatchToProps = dispatch => ({
 
  GetDeals:()=>dispatch(GetDeals())     
});



class Tours extends Component {

    constructor(props){
        super(props);

        this.state = {
          age: '',
          name: 'hai',
          labelWidth: 0,
        };

        this.props.GetDeals();
    }

    componentDidMount(){
      document.body.style.background = "#eee";
      
    }

    handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });

    };

    render(){

      const { classes } = this.props;

           
     

        return <Fragment>
   
                    <AppBar title="Dashboard"></AppBar>
                    
                   
                       <Drawer currentPage="deals"></Drawer>
                      

                      <div style={{width:'auto', marginLeft: drawerWidth+0, paddingTop:35, height:250 }}><div>  

                      <Table>
                            
                            <TableBody>
                            <TableRow>
                                    <TableCell style={{backgroundColor:'#fff', width:500,fontWeight:600, fontSize:16}}>  

                                        <h3> Deals & Discounts  </h3>
                                    </TableCell>

                            </TableRow> 

                            </TableBody>
                          </Table>
                      <div class="bok-sec" style={{padding:15, marginTop:0}}>  
            
            <div class="bk-mindiv">
          
              <div class="tab-pan">
                <div class="tab-bok active">
                  <table>
                    <tr style={{fontSize:16}}>
                      <th>Title</th>         
                      <th style={{textAlign:'center', width:'15%'}}>Discount Rate %</th>                
                      <th>Description</th>                
                      <th style={{width:"8%" }} ><p >Status</p></th>
                      <th></th>
                    </tr>
                    {this.props.deals.map(row => (
                    <tr key={row.id} style={{fontSize:15}} >
                      <td> {row.title}</td>
                      <td style={{textAlign:'center'}}>{row.discount}</td>
                      <td>{row.description}</td>
                      <td >{StatusChip(row.status)}</td >
                      <td>

                      <Button size="small" component={Link} to={"/DealsDetails/"+row.id} style={{ textAlign:"center",alignContent:'center',background:"#f7a414" }}  variant="contained"  color="primary" className={classes.button}>
                   <b style={{fontWeight:500}}>View</b> 
                </Button>
                      </td>
                     
                    </tr>
                 ))}
               
                    
                  </table>
                </div>
              
              </div>
            </div>
        </div>
      <div style={{ textAlign: 'right', backgroundColor:'#fff', height:'30' }} >
<CssBaseline />

</div>
     
                        </div>
                      </div> 


              </Fragment>
    }
}

Tours.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Tours);