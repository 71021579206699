import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import UserIcon from '@material-ui/icons/AccountCircle';
import ImageIcon from '@material-ui/icons/Image';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function SimpleList(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <List component="nav">
        <ListItem button>
        <Avatar>
          <ImageIcon />
        </Avatar>
          <ListItemText primary="Kamal" />
        </ListItem>
        <ListItem button>
        <Avatar>
          <ImageIcon />
        </Avatar>
          <ListItemText primary="Anura" />
        </ListItem>
        <ListItem button>
        <Avatar>
          <ImageIcon />
        </Avatar>
          <ListItemText primary="David" />
        </ListItem>
        <ListItem button>
        <Avatar>
          <ImageIcon />
        </Avatar>
          <ListItemText primary="Sanju" />
        </ListItem>
        <ListItem button>
        <Avatar>
          <ImageIcon />
        </Avatar>
          <ListItemText primary="Mariyan" />
        </ListItem>
        
      </List>
    
     
    </div>
  );
}

SimpleList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleList);

