import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';



export function MyActivities(tt,ts,k,page,perPage) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Loading data','info'));
        fetch(API_URL+'/api/supplier/activities?tt='+tt+'&ts='+ts+'&k='+k+'&page='+page+'&per_page='+perPage,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActivities(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}

// export function MyActivities() {

//     return function(dispatch) {
//         dispatch(startLoading());
//         fetch(API_URL+'/api/supplier/activities',{
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json;charset=utf-8',
//               'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
//             },
            
//         }).then(handleHTTPError)
//         .then((response)=>{
//               dispatch(endLoading());
//               response.json().then((json)=>{
//                   dispatch(endLoading());                   
//                   dispatch(storeActivities(json));                                                     
//               });
//         }).catch((error)=>{              
//           dispatch(endLoading());            
//           dispatch(SnackBar(true,'Could not load data','error'));
//         });
//     }   

// }



export function storeActivities(list) {
    return {
        type: 'STORE_ACTIVITIES',
        list: list
    }
}


export function GetActivity(activityID) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/activities?id='+activityID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActiveActivity(json));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}

export function storeActiveActivity(act) {
    return {
        type: 'STORE_ACTIVE_ACTIVITY',
        activity: act
    }
}


export function LockActiveActivity(act) {
    return {
        type: 'LOCK_ACTIVE_ACTIVITY',        
    }
}


export function updateActivity(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/activities/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(json.id)); 
                  dispatch(SnackBar(true,'Activity saved','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}

export function Trash(data) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Submitting for review','info'));
        fetch(API_URL+'/api/supplier/activities/trash',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                  
                  dispatch(MyActivities(data.tt,data.ts,data.k,data.page,data.perPage)); 
                  dispatch(SnackBar(true,'Moved to trash','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not move to trash','error'));
        });
    }   

}

export function ReviewSubmit(data) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Submitting for review','info'));
        fetch(API_URL+'/api/supplier/activities/review',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(data.id)); 
                  dispatch(SnackBar(true,'Submit complete!','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}


export function uploadPicture(data,fileName,type,aid){

    return function(dispatch) {
        dispatch(showUploadProgress(true,type));

        var fd = new FormData();
        fd.append('file',data);
        fd.append('name',fileName);
        fd.append('type',type);
        fd.append('aid',aid);

        axios.post(API_URL+'/api/supplier/activities/upload',fd,{            
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },           
        }).then((response)=>{
              dispatch(endLoading());
              dispatch(GetActivity(aid));
              dispatch(SnackBar(true,'Image uploaded','success'));
              dispatch(showUploadProgress(false,type));
        }).catch((error)=>{
            console.log(error)              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could upload image','error'));
          dispatch(showUploadProgress(false,type));
        });
    }
}


export function showUploadProgress(s,imgType) {
    return {
        type: 'IMAGE_UPLOADING',
        status: s,
        varType:imgType+'Uploading'
    }
}


export function RemoveImage(ActivityID,ImageId) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Removing Image','success'));
        fetch(API_URL+'/api/supplier/activities/image/remove?id='+ImageId,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetActivity(ActivityID)); 
                  dispatch(SnackBar(true,'Image removed','success'));                                                    
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}


export function SortImages(ActivityID,data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/activities/image/sort',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({images:data}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetActivity(ActivityID));
                  dispatch(SnackBar(true,'Images updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}


export function showInfoBox(show) {
    return {
        type: 'SHOW_HELP',
        show: show,
       
    }
}


export function FetchPolicies(type) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/settings/site/policy/get?type='+type,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storePolicies(json,type));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}



export function storePolicies(list,pType) {
    return {
        type: 'STORE_POLICIES',
        list: list,
        policyType:pType
    }
}

