import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

 
class ExpansiobPanal extends React.Component {


    constructor(props) {
        super(props);
        
        this.state = {
            
           
        };
       
      }
    
  
    


   

   


   

    render() {

    
        
        
  return (
    
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >{this.props.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>

          {this.props.type === 'overview' ? (<Typography>
            {this.props.content}
          </Typography>):null}

          {this.props.type === 'itinery' ? (
            <ul >
                {this.props.content.map((item)=>{
                    return <li style={{marginBottom:15,whiteSpace:'pre-wrap'}}><b>{item.label}</b> : {item.summery}</li>
                })}
            </ul>
          ):null}


          {this.props.type === 'inclusions' || this.props.type === 'exclusions' || this.props.type === 'kbyg' || this.props.type === 'kbyb'  ? (
            <ul >
                {this.props.content.map((item)=>{
                    return <li style={{marginBottom:15}}>{item}</li>
                })}
            </ul>
          ):null}

        

          {this.props.type === 'cancel' ? (<Typography>
            {this.props.content}
          </Typography>):null}

        </ExpansionPanelDetails>
      </ExpansionPanel>
   
         
  )
};

}

export default ExpansiobPanal;