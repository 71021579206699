import React from 'react';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#666ad1',
        main: '#303f9f',
        dark: '#001970',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff5c8d',
        main: '#d81b60',
        dark: '#a00037',
        contrastText: '#000',
      },

      third: {
        light: '#6d6d6d',
        main: '#424242',
        dark: '#1b1b1b',
        contrastText: '#fff',
      },
    },
  });


class ScrollDialog extends React.Component {
    state = {
        open: false,
        scroll: 'paper',
        width: 800,
    };

    handleClickOpen = scroll => () => {
        this.setState({ open: true, scroll });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <Button variant="contained" onClick={this.handleClickOpen('paper')} color="primary" >
                    View
                </Button>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    scroll={this.state.scroll}
                    aria-labelledby="scroll-dialog-title"
                >
                    <DialogTitle id="scroll-dialog-title">Booking Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="primary">
                            Status: Accept
            </DialogContentText>
                        <Divider />
                        <br />
                        <Typography variant="h6" component="h3">
                            Client Details:
        </Typography>

                        <Typography color="textSecondary">
                            First Name:
        </Typography>
                        <Typography component="p">
                            Jason M
        </Typography>
                        <br />
                        <Typography color="textSecondary">
                            Last Name:
        </Typography>
                        <Typography component="p">
                            Bairstow
        </Typography>
                        <br />
                        <Typography color="textSecondary">
                            Email:
        </Typography>
                        <Typography component="p">
                            jmbairstow@gmail.com
        </Typography>
                        <br />

                        <Typography color="textSecondary">
                            Phone No:
        </Typography>
                        <Typography component="p">
                            +44 7911 123456
        </Typography>
                        <br />
                        <Divider />

                        <br />

                        <Typography variant="h6" component="h3">
                            Tour Date Details
        </Typography>

                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} component="th">Tour Booking Date</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Tour Start Date</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Tour End Date</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>22 April 2019</TableCell>
                                    <TableCell>15 July 2019</TableCell>
                                    <TableCell>25 July 2019</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>


                        <Divider />

                        <br />

                        <Typography variant="h6" component="h3">
                            Pax Details
</Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} component="th">Adults</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Kids</TableCell>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 200, fontWeight: 600 }} >Infants</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>2</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>1</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>


                        <br />

                        <Typography variant="h6" component="h3">
                            Activity Details
</Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#f2f2f2', width: 500, fontWeight: 600 }} component="th">Description</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Visit Sri Dalada Maligawa and Museum</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                       
                        <br /> <br />
                    </DialogContent>
                   
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Accept
            </Button>
                        <Button onClick={this.handleClose} color="secondary">
                            Reject
            </Button>

                        <Button onClick={this.handleClose} color="third">
                            Cancel
            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ScrollDialog;