import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';



export function ActivityReviews(aid) { 

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/reviews/get?aid='+aid,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActivityReviews(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}


export function storeActivityReviews(list) {
    return {
        type: 'STORE_ACTIVITY_REVIEWS',
        list: list
    }
}

export function MyReviews() {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/reviews/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeReviews(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}



export function storeReviews(list) {
    return {
        type: 'STORE_REVIEWS',
        list: list
    }
}



