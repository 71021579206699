import React from 'react';



class NewStaticTextBox extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            value:this.props.value,
            updated:false
        }
    }

    static getDerivedStateFromProps(props, state){
        if(state.value !== props.value && state.updated === false ){
            return {
                value:props.value,
                updated:true
            }
        }
    }

    handleEditMode = () =>{
        this.setState({
            editMode:true
        });
    }


    handleApply =()=>{

        if(this.props.validate !== null){
            if(this.props.validate === 'phone'){
                var phoneno = /^[\d\.\-]+$/;
                if(!phoneno.test(this.state.value)){
                    this.props.onError(true,this.props.label+" number is invalid",'error');
                    return false;
                }
            }
        }    

        if(this.props.required === true){
            if(this.props.value === null){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }

            if(this.props.value.length === 0){
                this.props.onError(true,this.props.label+" can not be empty",'error');
                return false;
            }
        }

        this.props.UpdateData({field:this.props.field,value:this.state.value});

        this.setState({
            editMode:false
        });
    }

    render() {

    
        
        return <div>
            {this.state.editMode === false ? (
            <div className="profrm-row">
            <label>{this.props.label}&nbsp;</label>
            <input disabled={true}  onChange={this.props.onChange} type={this.props.type} value={this.state.value} style={{backgroundColor:'rgba(202, 201, 201, 0.21)'}} name=""/>
            <span onClick={()=>this.handleEditMode()}></span>
            <div style={{clear:'both'}}/>
            {this.props.review === true && this.props.rejected === false ? <i><small style={{color:'red'}}>In review</small></i>:<i><small style={{color:'red'}}>&nbsp;</small></i>}
            {this.props.rejected === true ? <i><small style={{color:'red'}}>Rejected</small></i>:<i><small style={{color:'red'}}>&nbsp;</small></i>}
            {this.props.emailVerified === 0 && this.props.field==='email' ? <i><small style={{color:'red'}}>Email not verified</small></i>:<i><small style={{color:'green'}}>&nbsp;</small></i>}
            {this.props.emailVerified === 1 && this.props.field==='email' ? <i><small style={{color:'green'}}>Email verified</small></i>:<i><small style={{color:'green'}}>&nbsp;</small></i>}
          </div>
            
            ) : (
            
              <div className="profrm-row active">
                <label>{this.props.label}</label>
                <input onChange={(e)=>this.setState({value:e.target.value})} type={this.props.type} value={this.state.value} name=""/>
                <span onClick={()=>this.handleApply()} style={{bottom:0}}></span>
                
              </div>
            )}
        </div>
    }
}

NewStaticTextBox.defaultProps = {
    type:'text',
    review:false,
    emailVerified:1,
    validate:null,
    rejected:false
}

export default NewStaticTextBox;