export default function reducer(state={
    loading:false,
    showAlert:false,
    alertMessage:null,
    alertVarient:'info',
    imageUploading:false,
    coverUploading:false,
    otherUploading:false,


    
},action){
switch(action.type){
    case "LOADIND_START":{
        return{...state,loading:true}
    }
    case "LOADIND_END":{ 
        return{...state,loading:false}
    }
    case "SHOW_ALERT":{
        return{...state,showAlert:action.show,alertMessage:action.message,alertVarient:action.varient}
    }
    case "IMAGE_UPLOADING":{
        console.log(action.type);
        return{...state,[action.varType]:action.status}
    }    
       


}
return state
}