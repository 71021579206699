export default function reducer(state={
    user:null,
    authToken : null,
    loggedIn:false,
    list:[],
    selectedUser:null, 
    dataopen:false,
    showAgreement:false,
      
},action){
switch(action.type){
    case "STORE_TOKEN":{
        return{...state,authToken:action.token}
    }
    case "STORE_USER":{
        return{...state,user:action.user,loggedIn:true,dataopen:true}
    }
    case "STORE_USERS":{
        return{...state,list:action.users.data}
    }
    case "USER_SELECTED":{
        return{...state,selectedUser:action.user}
    }
    case "USER_LOGOUT":{
        return{...state,authToken:null,loggedIn:false}
    }
    case "LOCK_USER_DATA":{
        return{...state,dataopen:false}
    }
    case "SHOW_AGREEMENT":{
        return{...state,showAgreement:action.agreement}
    }    
}
return state
}