import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { ViewRefundForm,respondRefund } from '../../Actions/FinanceActions';
import {showInfoBox} from '../../Actions/ActivityActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  
  

   


const mapStateToProps = (state) => {
    return {       
       refundRequestView : state.Finance.refundRequestView, 
       refundRequestViewData : state.Finance.refundRequestViewData, 
       refundRequestViewAction : state.Finance.refundRequestViewAction,
       show_help: state.Activities.show_help,
                      
    }
}

const mapDispatchToProps = dispatch => ({
  ViewRefundForm : (show,data,on) => dispatch(ViewRefundForm(show,data,on)),
  respondRefund:(amount,remark,data)=>dispatch(respondRefund(amount,remark,data)),
  showInfoBox : (data) => dispatch(showInfoBox(data)),
});

class RefundRequestView extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            amount:0,
            remark:0
        };
    }
    
    handleConfirmaton = () =>{
      
      this.props.refundRequestViewAction(3,this.props.refundRequestViewData);
      this.props.ViewRefundForm(false,null,null);
      this.props.showInfoBox(true);
    }

    handleChage = (e,field)=>{
        this.setState({
            [field]:e.target.value
        });
    }

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }


    getRefundStatus(status){        
      switch(status){
      case 0 :{ return "Pending" }
      case 1:{ return "Accepted" }
      case 2 :{ return "Rejected" }
      case 3 :{ return "Completed" }    
      }
    }

    render() {

        return (
            <div>             
              <Dialog
         open={this.props.show_help}
       // open={true}  
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{ this.props.ViewRefundForm(false,null,null);}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Help"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <div>
                  <table style={{width:500}}>
                     
                     <tr> 

                         <td>
                        <h3> Availability Type</h3>

                  <p  style={{textAlign:"left" }}> Instant – This option enables the customers to purchase this product without checking the availability.<br/><br/>

                    On availability – This option needs the customers to check availability before purchasing the product.</p> 
                         </td>
                     </tr>

                     <tr>

                        <td>
                        
                       <h3> Cut off Time</h3> 

                       <p style={{textAlign:"left" }}  > Related – Please select if the cut off time is related to starting date or starting time.    </p> 
                        </td>
                    </tr>
                    <tr>

                        <td>
                       <h3> Pax Define </h3> 

                       <p style={{textAlign:"left" }}  >Please define the age range of your adults, children and infants applicable for this product.</p> 
                 </td>
                    </tr>


                    <tr>

                        <td>
                             <h3>Availability Name</h3>   

                                <p style={{textAlign:"left" }}  >Please define the availability period. Ex. Summer, Winter</p> 
                        </td>
                    </tr>


                    <tr>

                        <td>
                           <h3> Location Group 1</h3>

                         <p style={{textAlign:"left" }}  >Please include starting locations with same rates in one location group.</p> 
                        </td>
                    </tr>








                  </table>
              </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{this.props.showInfoBox(false)}}   color="primary" style={{ color:'orange'}}  >
            Hide
          </Button>
         
        </DialogActions>
      </Dialog>
              </div>);
    }
    
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(RefundRequestView);
