export default function reducer(state={
    refundRequestShow:false,
    refundRequestData:null,
    refundRequestAction:null,
    refundsList:[],
    refundRequestView:false,
    refundRequestViewData:null,
    refundRequestViewAction:null,
    invoicesList:[]
    
},action){

    switch(action.type){
        case "SHOW_REFUND_REQUEST":{
            return{...state,refundRequestShow:action.show,refundRequestData:action.data,refundRequestAction:action.on}
        }
        case "STORE_REFUNDS":{
            return{...state,refundsList:action.list}
        }
        case "SHOW_REFUND_REQUEST_FORM":{
            return{...state,refundRequestView:action.show,refundRequestViewData:action.data,refundRequestViewAction:action.on}
        }
        case "STORE_INVOICES":{
            return{...state,invoicesList:action.list}
        }

               
    }

    return state;
}