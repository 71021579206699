export default function reducer(state={
    list: {
              upcoming:[],
              ongoing:[],
              pending:[],
              cancelled:[],
              completed:[],
              unseen:[],
              dispute:[]
           },
    activeBooking: {
                        activity: {title: null, sale_type: 0,},
                        activity_id: 0,
                        booking_reference: "",
                        booking_date:null,
                        contact_email: "",
                        contact_firstname: "",
                        contact_lastname: "",
                        contact_phone: "",
                        created_at: null,
                        deleted_at: null,
                        end_date: "",
                        hotel_address: "",
                        hotel_name: "",
                        id: 0,
                        num_adults: 0,
                        num_children: 0,
                        num_infants: 0,
                        payment_amount: 0,
                        payment_reference: "",
                        pickup_location: null,
                        remarks: null,
                        start_date: "",
                        status: 0,
                        travellers: [],
                        user: {id: 0, name: "", email: ""},
                        user_id: 0,
                        messages: [],
                        booking_amount:null,
                        snap_overview:"",
                        snap_cancel_policy:"",
                        snap_inclusions:[],
                        snap_exclusions:[],
                        snap_kbyb:[],
                        snap_kbyg:[],
                        snap_Itinerary:[],
                        net_price:null,
                        pickup:null,
                        ammendments:[],
                        contactable:false,
                        updated:false,
                },

    dashboard:{
        total_bookings:0,
        total_upcoming:0,
        total_ongoing:0,
        completed:0,
        total_earnings:0,
        total_pendings:0
    }

},action){
switch(action.type){
    case "STORE_BOOKINGS":{
        return{...state,list:action.list}
    }
    case "STORE_ACTIVE_BOOKING":{
        return{...state,
                    activeBooking:{
                                    activity: action.booking.activity,
                                    activity_id: action.booking.activity_id,
                                    booking_reference: action.booking.booking_reference,
                                    booking_date:action.booking.booking_date,
                                    contact_email: action.booking.contact_email,
                                    contact_firstname: action.booking.contact_firstname,
                                    contact_lastname: action.booking.contact_lastname,
                                    contact_phone: action.booking.contact_phone,
                                    created_at: action.booking.created_at,
                                    deleted_at: action.booking.deleted_at,
                                    end_date: action.booking.end_date,
                                    hotel_address: action.booking.hotel_address,
                                    hotel_name: action.booking.hotel_name,
                                    id: action.booking.id,
                                    num_adults:action.booking.num_adults,
                                    num_children: action.booking.num_children,
                                    num_infants: action.booking.num_infants,
                                    payment_amount: action.booking.payment_amount,
                                    payment_reference: action.booking.payment_reference,
                                    pickup_location: action.booking.pickup_location,
                                    remarks: action.booking.remarks,
                                    start_date: action.booking.start_date,
                                    status: action.booking.status,
                                    travellers: action.booking.travellers,
                                    user:action.booking.user,
                                    user_id: action.booking.user_id,
                                    messages: action.booking.messages,
                                    booking_amount:action.booking.booking_amount,
                                    snap_overview:action.booking.snap_overview,
                                    snap_cancel_policy:action.booking.snap_cancel_policy,
                                    snap_inclusions:action.booking.snap_inclusions,
                                    snap_exclusions:action.booking.snap_exclusions,
                                    snap_kbyb:action.booking.snap_kbyb,
                                    snap_kbyg:action.booking.snap_kbyg,
                                    snap_Itinerary:action.booking.snap_Itinerary,
                                    net_price:action.booking.net_price,
                                    travellers:action.booking.travellers,
                                    pickup:action.booking.pickup,
                                    ammendments:action.booking.ammendments,
                                    contactable:action.booking.contactable,
                                    updated:true,
                                }
              }
    }
    case "LOCK_ACTIVE_BOOKING":{
        return{...state,
                    activeBooking:{
                                    updated:false,
                                   }
              }
    }
    case "LOADIND_END":{
        return{...state,loading:false}
    }
    case "UPDATE_TOUR":{
        return {...state,activeTour:action.tour}
    }
    case "STORE_DASHBOARD":{
        return {...state,dashboard:action.data}
    }
}
return state
}
