import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';


function getDate (value) {
    if(value !== null && value !== ""){
        var date = new Date(value);
        return date.getFullYear()+"-"+((date.getMonth()+1) < 10 ? '0'+((date.getMonth()+1)):(date.getMonth()+1))+"-"+(date.getDate() < 10 ? '0'+date.getDate():date.getDate() );
    }

    return '';

}

export function MyBookings(keyword,bookingDate,tourStart,category) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/bookings?category='+category+'&traveldate='+getDate(tourStart)+'&bookingdate='+getDate(bookingDate)+'&keyword='+keyword,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeBookings(json));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

//fetch(API_URL+'/api/supplier/bookings',{

export function storeBookings(list) {
    return {
        type: 'STORE_BOOKINGS',
        list: list
    }
}


export function GetBooking(bookingID) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/bookings?id='+bookingID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeActiveBooking(json));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeActiveBooking(booking) {
    return {
        type: 'STORE_ACTIVE_BOOKING',
        booking: booking
    }
}


export function LockActiveActivity(act) {
    return {
        type: 'LOCK_ACTIVE_BOOKING',
    }
}


export function UpdateBookingStatus(bookingID,status) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Updating booking...','info'));
        fetch(API_URL+'/api/supplier/bookings/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({bid:bookingID,status:status}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetBooking(bookingID));
                  dispatch(SnackBar(true,'Booking updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not update booking','error'));
        });
    }

}


export function AddMessage(bookingID,message,visible) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Adding new message...','info'));
        fetch(API_URL+'/api/supplier/bookings/message',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({bid:bookingID,message:message,visibility:visible}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetBooking(bookingID));
                  dispatch(SnackBar(true,'Message added','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}

export function MyDashboard() {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/dashboard',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeDashboard(json));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function SetAmmendmentStatus(bookingId,ammendmentId,status) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Updating response...','info'));
        fetch(API_URL+'/api/supplier/bookings/ammendment/status',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({bid:bookingId,aid:ammendmentId,s:status}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetBooking(bookingId));
                  dispatch(SnackBar(true,'Updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not update response','error'));
        });
    }

}

export function SetAmmendmentAcknowlage(bookingId,ammendmentId,status) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Updating response...','info'));
        fetch(API_URL+'/api/supplier/bookings/ammendment/acknowlage',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({bid:bookingId,aid:ammendmentId,s:status}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(GetBooking(bookingId));
                  dispatch(SnackBar(true,'Updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not update response','error'));
        });
    }

}

export function storeDashboard(data) {
    return {
        type: 'STORE_DASHBOARD',
        data: data
    }
}
