import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Drawer from './Common/Drawer'
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Select from '@material-ui/core/Select';
import TablePagination from '@material-ui/core/TablePagination';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';


import Button from '@material-ui/core/Button';

import { Link } from 'react-router-dom';


import { MyActivities,Trash } from '../Actions/ActivityActions';
import Alert from './Common/Alert';
import { IconButton } from '@material-ui/core';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,

  textAlignCenter: {
    textAlign: 'center'
  }
});

const getStatus = (status) => {
  switch (status) {
    case 0: { return "Draft" }
    case 1: { return "Active" }
    case 2: { return "Rejected" }
    case 3: { return "In review" }
    case 4: { return "Banned" }// red     
    case 5: { return "Trashed" }// black
  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } }
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } }
    case 2: { return { backgroundColor: '#f44336', color: '#FFF' } }
    case 3: { return { backgroundColor: '#FFEB3B', color: '#000' } }
    case 4: { return { backgroundColor: '#f44336', color: '#FFF' } } //red #f44336    
    case 5: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
  }
}

const StatusChip = (status) => {
  return <Chip size="small" style={StatusStyle(status)} label={getStatus(status)}></Chip>
}


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    ActivitiesList: state.Activities.list
  }

}



const mapDispatchToProps = dispatch => ({

  MyActivities: (ttype, tsts, keyw,page,perPage) => dispatch(MyActivities(ttype, tsts, keyw,page,perPage)),
  Trash:(data)=>dispatch(Trash(data))
});



class Tours extends Component {

  constructor(props) {
    super(props);

    this.state = {
      age: '',
      name: 'hai',
      labelWidth: 0,
      keyword: "",
      tourtype: "",
      tourstatus: 1,
      page:0,
      per_page:10,
    };

    this.props.MyActivities(this.state.tourtype, this.state.tourstatus,this.state.keyword,this.state.page,this.state.per_page);
  }

  componentDidMount() {
    document.body.style.background = "#eee";

  }

  handleChange = name => event => {

    console.log(name);
    this.setState({
      [name]: event.target.value,
    }, () => this.searchTour());

  };

  handleTabChange = (tab) => {
    this.setState({ value: tab });
  };
  selectkeyword = (e) => {
    this.setState({
      keyword: e.target.value
    }, () => this.searchTour())
  }

  selecttourType = (e) => {
    this.setState({
      tourtype: e.target.value
    }, () => this.searchTour())
  }
  selecttourStatus = (e) => {
    this.setState({
      tourstatus: e.target.value
    }, () => this.searchTour())
  }


  searchTour = () => {

    this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword,this.state.page,this.state.per_page);
  }




  getTripType(type) {

    switch (type) {
      case 1: { return "Round tour" }
      case 2: { return "Day Tour" }
      case 3: { return "Activity" }
    }
  }

  getDuration(measure) {
    switch (measure) {
      case 1: { return "Days" }
      case 2: { return "Hours" }
      case 3: { return "Minutes" }
    }
  }


  handleChangeRowsPerPage = event => {
    this.setState({
      page:0,
      per_page:+event.target.value
    },()=>this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword,this.state.page,this.state.per_page));
  };

  handleChangePage = (event, newPage) => {    
    this.setState({
      page:newPage+1,      
    },()=>this.props.MyActivities(this.state.tourtype, this.state.tourstatus, this.state.keyword,this.state.page,this.state.per_page));
  };

  handleMoveToTrash = (data) => {
    if(data !== null){
      this.props.Trash({
        id:data.id,
        tt:this.state.tourtype,
        ts:this.state.tourstatus,
        k:this.state.keyword,
        page:this.state.page,
        perPage:this.state.per_page
       });
    }
    
  }


  render() {

    const { classes } = this.props;






    return <Fragment>

      <AppBar title="Dashboard"></AppBar>


      <Drawer currentPage="tours"></Drawer>

      <Alert />
      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>




        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: '#fff', width: 500, fontWeight: 600, fontSize: 16 }}>

                <h3> Tours & Activities  </h3>
              </TableCell>

              <TableCell style={{ backgroundColor: '#fff', width: 0, fontWeight: 600, fontSize: 16 }}><Button style={{ width: 200, marginLeft: 350, background: "#f7a414", alignContent: 'center', textAlign: "center" }} variant="contained" color="primary" component={Link} to="/NewActivity" className={classes.button}> <b> Create  </b> </Button></TableCell>

            </TableRow>

          </TableBody>
        </Table>

        <div class="bok-sec" style={{ padding: 15, marginTop: 0 }}>

          <div class="bk-mindiv">

            <div class="tab-pan">
              <div class="tab-bok active">
                  <div>
                  <Table width="50%" style={{ width: "50%", textAlign: "right", alignContent: 'right', alignSelf: 'center', float: 'right' }} className={classes.table}  >

<tr>
  <td align="right" >

    <FormControl className={classes.formControl}>
      <TextField
        label='Product Name/ID'
        value={this.state.keyword}
        onChange={this.selectkeyword}
      >

      </TextField>
    </FormControl>

  </td>

  <td align="right" >

    <FormControl className={classes.formControl}>
      <InputLabel shrink={true} htmlFor="status-native-simple">Tour type</InputLabel>
      <Select
        native
        // value={this.state.tour_type}

        // onChange={this.handleChange('tour_type')}
        // onChange={this.handleChange('tour_type')}
        onChange={this.selecttourType}
        


        inputProps={{
          name: 'tour_type',
          id: 'status-native-simple',
        }}
      >
        <option value="" >  All  </option>
        <option value="1">  Round Tour  </option>
        <option value="2">  Day Tour    </option>

      </Select>
    </FormControl>

  </td>

  <td alighn="right" >

    <FormControl className={classes.formControl}>
      <InputLabel shrink={true} htmlFor="status-native-simple"> Status </InputLabel>
      <Select
        native
        // value={this.state.status}
        label="status"
        // onChange={this.handleChange('status')}
        onChange={this.selecttourStatus}
        value={this.state.tourstatus}
        shrink={true}
        inputProps={{
          name: 'status',
          id: 'status-native-simple',
        }}
      >
        <option value="" >  All  </option>
        <option value="0">  Draft  </option>
        <option value="1">  Active </option>
        <option value="2"> Rejected  </option>
        <option value="3">  In review  </option>        
        <option value="4">Banned</option>
        <option value="5">Trashed</option>

      </Select>
    </FormControl>

  </td>


</tr>


</Table>
                  <div style={{clear:'both'}} />
                  </div>

                <div>
                {this.props.ActivitiesList !== null ?     
                <TablePagination                  
                  component="div"
                  count={this.props.ActivitiesList.total}
                  rowsPerPage={this.props.ActivitiesList.per_page}
                  page={this.props.ActivitiesList.current_page-1}
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />:null}
                </div>          
                          

                <table>
                  <tr style={{ fontSize: 16 }}>
                    <th style={{ width: '5%' }} >#</th>
                    <th style={{ width: '40%' }}>Tour Name</th>
                    <th style={{ width: '15%' }}>Tour Type</th>
                    <th style={{ width: '10%' }} >Duration</th>
                    <th style={{ width: '15%' }}>Category</th>
                    <th style={{ width: '10%' }}>Status</th>
                    <th style={{ width: '5%' }}></th>
                  </tr>
                  {this.props.ActivitiesList !== null ? this.props.ActivitiesList.data.map((act, index) => (
                    <tr key={act.id} style={{ fontSize: 15 }}>
                      <td>{this.props.ActivitiesList.total- ((this.props.ActivitiesList.from+index)-1)}</td>
                      <td>{act.title} <br /> <small style={{ fontStyle: 'italic' }} >TourID : {act.tour_id}</small> </td>
                      <td>{this.getTripType(act.tour_type)}</td>
                      <td>{act.duration_value} &nbsp;{this.getDuration(act.duration)}</td>
                      <td>{act.category.length > 0  ? act.category[0].category.name:null} </td>
                      <td>{StatusChip(act.status)}</td>
                      <td className={classes.textAlignCenter}  >
                        <IconButton component={Link} to={"/EditActivity/" + act.id} color="white" className={classes.button} >
                          <VisibilityRoundedIcon />
                        </IconButton>
                      </td>
                      <td className={classes.textAlignCenter}  >
                        {act.status !== 5 ? <IconButton onClick={()=>this.handleMoveToTrash(act)} color="white" className={classes.button}>
                          <DeleteIcon className={classes.rightIcon} />
                        </IconButton> : null }
                        


                      </td>
                    </tr>
                  )) : null}

                  {/* index+1 */}

                </table>

                {this.props.ActivitiesList !== null ?     
                <TablePagination                  
                  component="div"
                  count={this.props.ActivitiesList.total}
                  rowsPerPage={this.props.ActivitiesList.per_page}
                  page={this.props.ActivitiesList.current_page-1}
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />:null}

              </div>
              <div class="tab-bok">

              </div>
            </div>
          </div>
        </div>
      </div>
      </div>


    </Fragment>
  }
}

Tours.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Tours);