import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';


export function GetDeals(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/deals/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                    dispatch(storeDeals(json));                  
                    dispatch(endLoading());                                                      
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Error loading data','error'));
        });
        
    }
}


export function storeDeals(deals){
    return{type:'STORE_DEALS',deals:deals};
}

export function GetDeal(dealID){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/supplier/deals/get?id='+dealID,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
          }
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(storeActiveDeal(json));             
                                                                        
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Error loading locations','error'));
      });
      
  }
}


export function storeActiveDeal(deal){
  return{type:'STORE_ACTIVE_DEAL',deal:deal};
}

export function LockActiveDeal(){
  return{type:'LOCK_ACTIVE_DEAL'};
}


export function UpdateDeal(data){

  return function(dispatch) {
      dispatch(SnackBar(true,'Uploading image, please wait...','info'));

      var fd = new FormData();

      if(data.uploadImage !== null){
        fd.append('file',data.uploadImage,data.uploadImage.name);
      }     
      
      fd.append('id',data.id);
      fd.append('title',data.title);
      fd.append('description',data.description);
      fd.append('discount',data.discount);
      

      axios.post(API_URL+'/api/admin/deals/update',fd,{            
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
          },           
      }).then((response)=>{
            dispatch(endLoading());            
            dispatch(GetDeal(response.data.id));
            dispatch(SnackBar(true,'Your profie was updated','success'));
      }).catch((error)=>{
          console.log(error)              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Data saving error','error'));
      });
  }
}

export function GetSubscription(dealID){

  return function (dispatch){
      dispatch(startLoading());
      fetch(API_URL+'/api/supplier/deals/subscribe?id='+dealID,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
          }
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(storeActiveSubscrption(json));            
                                                                        
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Error loading locations','error'));
      });
      
  }
}


export function storeActiveSubscrption(subscriptions){
  return{type:'STORE_ACTIVE_SUBSCRIPTION',sub:subscriptions};
}

export function LockActiveSubscrption(){
  return{type:'LOCK_ACTIVE_SUBSCRITION'};
}

export function UpdateSubscription(data){

  return function (dispatch){
      dispatch(startLoading());
      dispatch(SnackBar(true,'Updating subscription','info'));
      fetch(API_URL+'/api/supplier/deals/subscribe',{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
          },
          body:JSON.stringify(data),
      }).then(handleHTTPError)
      .then((response)=>{
            dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(GetSubscription(data.deal_id));             
                  dispatch(SnackBar(true,'Subsciption updated','success'));                                                      
            });
      }).catch((error)=>{              
        dispatch(endLoading());            
        dispatch(SnackBar(true,'Error loading locations','error'));
      });
      
  }
}
