import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import Drawer from './Common/Drawer'

import Table from '@material-ui/core/Table'; 
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

 
import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';
import './../activity/css/style.css';
import './../activity/css/css.css';



import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { SnackBar } from '../Actions/CommonActions';


import { fetchUser, updateUser,uploadWorkLogo,uploadPersonalLogo,lockUserData,uploadDocument,UpdateField,UpdateRemoveField } from '../Actions/AuthActions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { fetchCategories } from '../Actions/CategoryAction';

import Alert from './Common/Alert';
import StaticTextBox from '../Components/StaticTextBox';
import NewStaticTextBox from '../Components/NewStaticTextBox';
import Newtextarea from '../Components/Newtextarea';
import Background from './../Img/p1.png';
import { Chip } from '@material-ui/core';

const drawerWidth = 240;

const styles = theme => ({

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
});


const getStatus = (status) => {
  switch (status) {
    case 0: { return "Pending" } //gray
        case 1: { return "Approved" } //green
        case 3: { return "In review" } //yellow
        case 4: { return "Banned" }// red 
        case 2: { return "Rejected" }// black

  }
}

const StatusStyle = (status) => {
  switch (status) {
    case 0: { return { backgroundColor: '#90A4AE', color: '#FFF' } } //gray #90A4AE
    case 1: { return { backgroundColor: '#4CAF50', color: '#FFF' } } //green #4CAF50                  
    case 3: { return { backgroundColor: '#FFEB3B', color: '#000' } } //yellow #FFEB3B
    case 4: { return { backgroundColor: '#f44336', color: '#FFF' } } //red #f44336
    case 2: { return { backgroundColor: '#1a1818', color: '#FFF' } } //black #f44336
   
  }
}

const StatusChip = (status) => {
  return <div><Chip size="small" style={{ width:'3%'}}  style={StatusStyle(status)} label={getStatus(status)}></Chip></div> 
}


const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    user: state.Auth.user,
    categories: state.Categories.categories,
    alertVarient: state.Common.alertVarient,
    userDataOpen: state.Auth.dataopen,
  }
}

const mapDispatchToProps = dispatch => ({
 
  fetchCategories: () => dispatch(fetchCategories()),
  fetchUser:()=>dispatch(fetchUser()),
  updateUser:(data)=>dispatch(updateUser(data)),
  uploadWorkLogo:(file)=>dispatch(uploadWorkLogo(file)),
  uploadPersonalLogo:(file)=>dispatch(uploadPersonalLogo(file)),
  lockUserData:()=>dispatch(lockUserData()),
  uploadDocument:(file,type)=>dispatch(uploadDocument(file,type)),
  UpdateField:(field,value)=>dispatch(UpdateField(field,value)),
  UpdateRemoveField:(field)=>dispatch(UpdateRemoveField(field)),
  SnackBar:(show,message,varient)=>dispatch(SnackBar(show,message,varient)),
});

class Account extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      name: null,
      email: null,
      user_type: 1,
      status: 1,
      work_name: null,
      work_address: null,
      work_reg_number: null,
      work_fix_phone: null,
      work_mobile: null,
      work_avatar: null,
      contact_name: null,
      contact_NIC: null,
      contact_home_phone: null,
      contact_mobile_phone: null,
      contact_address: null,
      contact_avatar: null,
      br_copy: null,
      tb_licence_copy: null,
      ins_copy: null,
      billing_proof:"",
      categories: [],
      work_name_review:false,
      work_address_review:false,
      work_reg_number_review:false,
      work_fix_phone_review:false,
      work_mobile_review:false,
      contact_name_review:false,
      contact_NIC_review:false,
      contact_home_phone_review:false,
      contact_mobile_phone_review:false,
      contact_address_review:false,
      bank_name_review:false,
      bank_branch_review:false,
      bank_account_review:false,
      bank_account_name_review:false,
      work_logo_review:false,
      contact_logo_review:false,
      br_copy_review:false,
      tb_licence_copy_review:false,
      ins_copy_review:false,
      billing_proof_review:false,
      email_verified:0,
      rejected_fields:[]
    };

    this.timeout = null;
    this.props.fetchCategories();
    this.props.fetchUser();

    this.handleCatChange = this.handleCatChange.bind(this);
  }



  static getDerivedStateFromProps(props, state){



      if(props.userDataOpen){

          props.lockUserData();
          
          return {
            id: props.user.id,       
            work_name: props.user.work_name,
            work_address: props.user.work_address,
            work_reg_number: props.user.work_reg_number,
            work_fix_phone: props.user.work_fix_phone,
            work_mobile: props.user.work_mobile,
            work_avatar: props.user.work_avatar,
            contact_name: props.user.contact_name,
            contact_NIC: props.user.contact_NIC,
            contact_home_phone: props.user.contact_home_phone,
            contact_mobile_phone: props.user.contact_mobile_phone,
            contact_address: props.user.contact_address,
            contact_avatar: props.user.contact_avatar,
            br_copy: props.user.br_copy,
            tb_licence_copy: props.user.tb_licence_copy,
            ins_copy: props.user.ins_copy,
            billing_proof:props.user.billing_proof,
            categories: props.user.categories,
            email:props.user.email,
            work_name_review:props.user.work_name_review,
            work_address_review:props.user.work_address_review,
            work_reg_number_review:props.user.work_reg_number_review,
            work_fix_phone_review:props.user.work_fix_phone_review,
            work_mobile_review:props.user.work_mobile_review,
            contact_name_review:props.user.contact_name_review,
            contact_NIC_review:props.user.contact_NIC_review,
            contact_home_phone_review:props.user.contact_home_phone_review,
            contact_mobile_phone_review:props.user.contact_mobile_phone_review,
            contact_address_review:props.user.contact_address_review,
            bank_name_review:props.user.bank_name_review,
            bank_branch_review:props.user.bank_branch_review,
            bank_account_review:props.user.bank_account_review,
            bank_account_name_review:props.user.bank_account_name_review,
            work_logo_review:props.user.work_logo_review,
            contact_logo_review:props.user.contact_logo_review,
            br_copy_review:props.user.br_copy_review,
            tb_licence_copy_review:props.user.tb_licence_copy_review,
            ins_copy_review:props.user.ins_copy_review,
            billing_proof_review:props.user.billing_proof_review,
            email_verified:props.user.email_verified,
            rejected_fields:props.user.rejected_fields,
        }
      } 
      
      return null;

      
  }


  


  componentDidMount() {
    document.body.style.background = "#eee";
  }


  handleCatChange = (e,index) => {
    const item = e.target.id;
    const isChecked = e.target.checked;
    this.setState({
        ...this.state,
        categories:Object.values({
            ...this.state.categories,
            [index]:{
              ...this.state.categories[index],
              checked: isChecked
            }
          })
    },()=>{
      if(this.timeout !== null){
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(
                                  () => {
                                    this.saveAccount();
                                  }
                                  ,
                                  1000
                              );
      
    });
    

  }  

  handleChange = name => event => {
    
    this.setState({
      [name]: event.target.value,
    });
    
  };



  saveAccount = ()=>{
      
      this.props.updateUser(this.state);
  }


  uploadWorkLogo = (e) =>{

    if(e.target.files[0] != null){
      this.props.uploadWorkLogo(e.target.files[0]);
    }
           
  }


  uploadDocument = (e,type) =>{

    if(e.target.files[0] != null){
      this.props.uploadDocument(e.target.files[0],type);
    }
           
  }

  uploadPersonalLogo = (e) =>{

    if(e.target.files[0] != null){
      this.props.uploadPersonalLogo(e.target.files[0]);
    }
         
}

  render() {

    const { classes } = this.props;



    return <Fragment>

      <AppBar title="Dashboard"></AppBar>


      <Drawer currentPage="account"></Drawer>
      <Alert />

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}>
        
        
      
        <Table>

          <TableBody>
            <TableRow>
                  <TableCell style={{backgroundColor:'#fff', width:500,fontWeight:600, fontSize:16}}> 
                        

                                <h3> Profile  </h3>
                  </TableCell>

            </TableRow>

            


          </TableBody>
        </Table>






        
            <div className="pro-div" style={{margin:15}}>
              <div>
                <div style={{float:'right'}}>
                {StatusChip(this.state.status)}
                </div>
                
              <h5>Company Details</h5>
              </div>
              <div style={{clear:'both'}}></div>
              
              <p>Please fill your company details correctly and provide at least two contact numbers. </p>
              <div className="profrm-min">
                <div className="profrm-div">
             
                     <div className="profrm-row">
                    

                  </div>  

                  <div width="100%"  style={{width:'100%'}}>
                  <div className="profrm-row">
                    <label>Supplier ID</label>  
                    <input disabled={true} style={{backgroundColor:'rgba(202, 201, 201, 0.21)'}} type="text" name="id" value={this.state.id} onChange={this.handleChange('id')}   />
                  </div>
           
                  <NewStaticTextBox label="Company Name"  UpdateData={this.props.UpdateField} field='work_name' style={{ fontFamily: 'Roboto , sans-serif' }} review={this.state.work_name_review} rejected={this.state.rejected_fields.includes("work_name")}  value={this.state.work_name} onChange={this.handleChange('work_name')}/>
                
                    <NewStaticTextBox label="Company Register Number" UpdateData={this.props.UpdateField} field='work_reg_number'  rejected={this.state.rejected_fields.includes("work_reg_number")} review={this.state.work_reg_number_review}  value={this.state.work_reg_number} onChange={this.handleChange('work_reg_number')}/>
               
                   <NewStaticTextBox validate="phone" onError={this.props.SnackBar} label="Land Telephone" UpdateData={this.props.UpdateField} rejected={this.state.rejected_fields.includes("work_fix_phone")} field='work_fix_phone' review={this.state.work_fix_phone_review} value={this.state.work_fix_phone} onChange={this.handleChange('work_fix_phone')}/>
                
                    <NewStaticTextBox validate="phone" onError={this.props.SnackBar} label="Mobile Telephone" UpdateData={this.props.UpdateField} rejected={this.state.rejected_fields.includes("work_mobile")} field='work_mobile' review={this.state.work_mobile_review}  value={this.state.work_mobile} onChange={this.handleChange('work_mobile')}/>
                                  
                  <NewStaticTextBox label="Address"  UpdateData={this.props.UpdateField} field='work_address' review={this.state.work_address_review} rejected={this.state.rejected_fields.includes("work_address")} value={this.state.work_address} onChange={this.handleChange('work_address')}/>
                  </div>
                </div>
                <div className="profrm-up">
                {this.state.work_avatar != null ? 


                (<div>
                  {this.state.rejected_fields.includes("work_avatar") === true ? <i><small style={{color:'red',marginLeft:70}}>Rejected</small></i>:<i><small >&nbsp;</small></i>}
                  {this.state.contact_logo_review === true && this.state.rejected_fields.includes("work_avatar") === false ? <i><small style={{color:'red',marginLeft:70}}>In review</small></i>:<i><small >&nbsp;</small></i>}
                  <label htmlFor="file-work-logo" >
                  <div className="profrm-fil" style={{backgroundImage: 'url('+this.state.work_avatar+')',cursor:'pointer'}}  >
                  <div >
                   
                        
                        <input
                                accept="image/*"
                                style={{ display: "none"}}
                                id="file-work-logo"
                                multiple
                                type="file"
                               
                                onChange ={(e)=>this.uploadWorkLogo(e,'work_avatar')}
                              />
                                 
                                
                                <p style={{textAlign:'center', fontSize:11}}></p>
                              
                            
                      </div>
                    <span>Image size should be 210*210</span>
                  </div>
                  </label>
                  </div>):(

                    <div> <input
                    accept="image/*"
                    style={{ display: "none"}}
                    id="file-work-logo"
                    multiple
                    type="file"
                    onChange ={this.uploadWorkLogo}
                  /> </div>

                    
                  )}
                </div>

              </div>
              <div className="clearfix"></div>

              <div className="pro-hit"></div>
              <h5>Contact Person Details</h5>
              <p>Contact person should be available to answer all phone calls and provide good customer support. </p>
              <div class="profrm-min cp-min"> 
                <div class="profrm-div">
                    <NewStaticTextBox label="Name" UpdateData={this.props.UpdateField} rejected={this.state.rejected_fields.includes("contact_name")} field='contact_name' review={this.state.contact_name_review} value={this.state.contact_name} onChange={this.handleChange('contact_name')}/>
                
                    <NewStaticTextBox label="NIC Number" UpdateData={this.props.UpdateField} rejected={this.state.rejected_fields.includes("contact_NIC")} field='contact_NIC' review={this.state.contact_NIC_review} value={this.state.contact_NIC} onChange={this.handleChange('contact_NIC')}/>
               
                    <NewStaticTextBox validate="phone" onError={this.props.SnackBar} label="Home Telephone" UpdateData={this.props.UpdateField} rejected={this.state.rejected_fields.includes("contact_home_phone")} field='contact_home_phone' review={this.state.contact_home_phone_review}  value={this.state.contact_home_phone} onChange={this.handleChange('contact_home_phone')}/>
                
                    <NewStaticTextBox validate="phone" onError={this.props.SnackBar} label="Mobile Telephone" UpdateData={this.props.UpdateField} rejected={this.state.rejected_fields.includes("contact_mobile_phone")} field='contact_mobile_phone' review={this.state.contact_mobile_phone_review} value={this.state.contact_mobile_phone} onChange={this.handleChange('contact_mobile_phone')}/>
                
                    <NewStaticTextBox label="Address" UpdateData={this.props.UpdateField} field='contact_address' review={this.state.contact_address_review} rejected={this.state.rejected_fields.includes("contact_address")} value={this.state.contact_address} onChange={this.handleChange('contact_address')}/>
                 
                </div>

                <div className="profrm-up">
                {this.state.contact_avatar != null ? (<div>
                  {this.state.rejected_fields.includes("contact_avatar") === true ? <i><small style={{color:'red',marginLeft:70}}>Rejected</small></i>:<i><small >&nbsp;</small></i>}
                  {this.state.work_logo_review === true && this.state.rejected_fields.includes("contact_avatar") === false ? <i><small style={{color:'red',marginLeft:70}}>In review</small></i>:<i><small >&nbsp;</small></i>}
                  <label htmlFor="file-personal-logo" >
                  <div className="profrm-fil" style={{backgroundImage: 'url('+this.state.contact_avatar+')',cursor:'pointer'}}  >
                  <div >
                   
                  
                              <input type="file"  name=""  id="file-personal-logo"  onChange ={(e)=>this.uploadPersonalLogo(e,'contact_avatar')}/>
                              
                                
                                <p style={{textAlign:'center', fontSize:11}}></p>
                              
                            
                            
                      </div>
                    <span>Image size should be 210*210</span>
                  </div>
                  </label>             
                  </div>):(

                    <div>  <input type="file" style={{ display: "none"}} name=""  id="file-personal-logo" onChange ={this.uploadPersonalLogo}/></div>

                    
                  )}
                </div>

              </div>
              <div className="clearfix"></div>
              <div className="pro-hit"></div>
              <h5>Email &amp; Password</h5>
              <p>Change your account email address and password.</p>              
              <div class="profrm-min cp-min"> 
                <div class="profrm-div">
                    <NewStaticTextBox label="Email" UpdateData={this.props.UpdateField} field='email' value={this.state.email} emailVerified={this.state.email_verified} onChange={this.handleChange('contact_name')}/>
                
                    <NewStaticTextBox label="Password" type="password" UpdateData={this.props.UpdateField} field='password' />
               
                </div>

              </div>
              
              <div className="clearfix"></div>
            </div>
            <div className="tucat"  style={{margin:15}}>
              <div>
                <h5>Tour Categories</h5>
                <p>Please select the tour categories that you mostly sell. This will help us to cross sell your products effectively. </p>
                <div className="tctchek">
                  <div className="tctchek-chk">
                    {/* <label><input type="checkbox" />Wild Life</label> */}
                    {this.state.categories.map((cat, index) => {
                      return <FormControlLabel

                        control={
                          <Checkbox name={cat.label} id={cat.id} color="primary" style={{ color:'orange'}} checked={cat.checked} onChange={(e)=>{this.handleCatChange(e,index)}}
                          />
                        }
                        label={cat.label} />
                    })}
                    {/* <label><input type="checkbox" />Adventure</label>
                    <label><input type="checkbox" />Water Sport</label> */}
                  </div>
                  
                  <div className="clearfix"></div>
                </div>

              </div>

              <div >

                <h5>Upload Documents</h5>
                <p>Please upload scanned copies of all required company documents. Business registration or Guide license is mandatory.</p>


                <div className="uplo-doc">
                  <div className="uplo-mun">


                  { this.state.br_copy !== "" ? ( <div>
                      {this.state.rejected_fields.includes("br_copy") === true ? <i><small style={{color:'red'}}>Rejected</small></i>:<i><small >&nbsp;</small></i>}
                      {this.state.br_copy_review === true && this.state.rejected_fields.includes("br_copy") === false ? <i><small style={{color:'red'}}>In review</small></i>:<i><small >&nbsp;</small></i>}
                      <div className="uplo-div added" style={{backgroundImage: 'url('+this.state.br_copy+')'}}>
                        <div className="vop">
                          <a href={this.state.br_copy} target="_target" className="viw" ></a>
                          <span className="dele" onClick={()=>this.props.UpdateRemoveField('br_copy')}></span>
                        </div>
                      </div>
                      <p>Business Registration / Guide Licence*</p>
                      <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                    </div>):(
                      <div>
                      <div className="uplo-div">
                        <div className="bt"><input type="file" onChange ={(e)=>this.uploadDocument(e,'br')} name=""/></div>
                      </div>
                      <p>Business Registration / Guide Licence*</p>
                      <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                    </div>
                    )}  
                  
                   
                  { this.state.tb_licence_copy !== "" ? ( <div>
                    {this.state.rejected_fields.includes("tb_licence_copy") === true ? <i><small style={{color:'red'}}>Rejected</small></i>:<i><small >&nbsp;</small></i>}
                    {this.state.tb_licence_copy_review === true && this.state.rejected_fields.includes("tb_licence_copy") === false ? <i><small style={{color:'red'}}>In review</small></i>:<i><small >&nbsp;</small></i>}
                      <div className="uplo-div added" style={{backgroundImage: 'url('+this.state.tb_licence_copy+')'}}   >
                          <div className="vop">
                          <a href={this.state.tb_licence_copy} target="_target" className="viw" ></a>
                          <span className="dele" onClick={()=>this.props.UpdateRemoveField('tb_licence_copy')}></span>
                          </div>
                      </div>
                      <p>Tourist Board Licence</p>
                      <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                    </div>):(
                      <div>
                      <div className="uplo-div">
                        <div className="bt"><input type="file"onChange ={(e)=>this.uploadDocument(e,'tbl')} name=""/></div>
                      </div>
                      <p>Tourist Board Licence</p>
                      <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                    </div>
                    )}  
                  


                    
                  {  this.state.ins_copy !== "" ? ( <div>
                    {this.state.rejected_fields.includes("ins_copy") === true ? <i><small style={{color:'red'}}>Rejected</small></i>:<i><small >&nbsp;</small></i>}
                    {this.state.ins_copy_review === true && this.state.rejected_fields.includes("ins_copy") === false ? <i><small style={{color:'red'}}>In review</small></i>:<i><small >&nbsp;</small></i>}
                      <div className="uplo-div added" style={{backgroundImage: 'url('+this.state.ins_copy+')'}}   >
                           <div className="vop">
                          <a href={this.state.ins_copy} target="_target" className="viw" ></a>
                          <span className="dele" onClick={()=>this.props.UpdateRemoveField('ins_copy')}></span>
                        </div>
                      </div>
                      <p>Insurence</p>
                      <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                    </div>):(
                      <div>

                      <div className="uplo-div">
                        <div className="bt"><input type="file" onChange ={(e)=>this.uploadDocument(e,'ins')} name=""/></div>
                      </div>
                      <p>Insurence</p>
                      <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                    </div>
                    )}

                    {  this.state.billing_proof !== "" ? ( <div>
                      {this.state.rejected_fields.includes("billing_proof") === true ? <i><small style={{color:'red'}}>Rejected</small></i>:<i><small >&nbsp;</small></i>}
                      {this.state.billing_proof_review === true && this.state.rejected_fields.includes("billing_proof") === false ? <i><small style={{color:'red'}}>In review</small></i>:<i><small >&nbsp;</small></i>}
                      {this.state.br_copy_review === true ? <i><small style={{color:'red'}}>In review</small></i>:<i><small >&nbsp;</small></i>}
                      <div className="uplo-div added" style={{backgroundImage: 'url('+this.state.billing_proof+')'}}   >
                           <div className="vop">
                          <a href={this.state.billing_proof} target="_target" className="viw" ></a>
                          <span className="dele" onClick={()=>this.props.UpdateRemoveField('billing_proof')}></span>
                        </div>
                      </div>
                      <p>Bank Statement</p>
                      <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                    </div>):(
                      <div>

                      <div className="uplo-div">
                        <div className="bt"><input type="file" onChange ={(e)=>this.uploadDocument(e,'billing_proof')} name=""/></div>
                      </div>
                      <p>Bank Statement</p>
                      <span>Only PDF, JPG, PNG, DOCX, DOC files below 2MB formats are allowed to upload</span>
                    </div>
                    )}  
  

                   

                  </div> 
                  <div className="clearfix"></div>                 
                  
                </div>
              </div>
            </div>
</div>

       



   

    </Fragment>
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Account);