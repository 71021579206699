import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import './../activity/css/style.css';
import './../activity/css/css.css'; 
import NewStaticTextBox from '../Components/NewStaticTextBox';
 
import { MyBookings } from '../Actions/BookingActions';
import {MyDashboard} from '../Actions/BookingActions';

import { compose } from 'redux';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';


import { updateBank, fetchUser,uploadPersonalLogo,lockUserData,UpdateField } from '../Actions/AuthActions';

import {GetRefunds,ViewRefund,ShowRefund,GetInvoices} from '../Actions/FinanceActions';
import StaticTextBox from './StaticTextBox';

const drawerWidth = 240;


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  indicator: {
    backgroundColor: '#FFEB3B',
  },
});

const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        loggedIn : state.Auth.loggedIn,
        user: state.Auth.user, 
        userDataOpen: state.Auth.dataopen,
        refunds: state.Finance.refundsList,
        invoices:state.Finance.invoicesList,
    }
}




const mapDispatchToProps = dispatch => ({
  
  lockUserData:()=>dispatch(lockUserData()),
  fetchUser:()=>dispatch(fetchUser()), 
  lockUserData:()=>dispatch(lockUserData()),
  updateBank:(data)=>dispatch(updateBank(data)),
  GetRefunds:()=>dispatch(GetRefunds()),
  ViewRefund:(data)=>dispatch(ViewRefund(data)),
  ShowRefund:(data)=>dispatch(ShowRefund(data)),
  GetInvoices:()=>dispatch(GetInvoices()),
  UpdateField:(field,value)=>dispatch(UpdateField(field,value))     
});

class TabsWrappedLabel extends React.Component {
 
  constructor(props){
    super(props);


    
    
        this.state = {
          value: 'one',
          bank_name:"",
          bank_branch:"",
          bank_account:"",
          bank_name_review:false,
          bank_branch_review:false,
          bank_account_review:false,
          bank_account_name_review:false,
        }
       
  


        this.props.fetchUser();

        this.props.GetRefunds();
        this.props.GetInvoices();
         
  }




 


  static getDerivedStateFromProps(props, state){

    if(props.userDataOpen){

        props.lockUserData();

        return {  
          bank_name: props.user.bank_name,
          bank_branch: props.user.bank_branch,
          bank_account_name: props.user.bank_account_name,
          bank_account: props.user.bank_account,
          bank_name_review:props.user.bank_name_review,
          bank_branch_review:props.user.bank_branch_review,
          bank_account_review:props.user.bank_account_review,
          bank_account_name_review:props.user.bank_account_name_review,
      }
    } 
    
    return null;

    
}
 
 

  handleChange = (event, value) => {
    this.setState({ value });
  };   

handleTabChange = (tab) => {
    this.setState({ value: tab});
  };

  

  handleTxtChange = name => event => {
    
    this.setState({
      [name]: event.target.value,
    });
  };

 handleUpdateBankInfo = () => {
   this.props.updateBank({
    bank_name: this.state.bank_name,
    bank_branch: this.state.bank_branch,
    bank_account: this.state.bank_account,
    bank_account_name: this.state.bank_account_name,
   });
 }

 handleView = (data) => {
  this.props.ViewRefund(data);
}


showRefund = (data) =>{
  this.props.ShowRefund(data);
}

 getRefundStatus(status){        
  switch(status){
  case 0 :{ return "Pending" }
  case 1:{ return "Accepted" }
  case 2 :{ return "Rejected" }
  case 3 :{ return "Completed" }    
  }
}

  render() {
    const { classes } = this.props;
    const { value } = this.state;

 
     
    return (
      <div className={classes.root} style={{backgroundColor:'#fafbfb'}}>  
     
              <div className="bk-menu">
                <a style={{cursor:'pointer'}} className={ this.state.value === 'one' ? 'active':null}  onClick={()=>this.handleTabChange('one')}>Bank Details</a>                
                <a style={{cursor:'pointer'}} className={ this.state.value === 'two' ? 'active':null}  onClick={()=>this.handleTabChange('two')}>Supplier Invoices</a>             
                <a style={{cursor:'pointer'}} className={ this.state.value === 'three' ? 'active':null}  onClick={()=>this.handleTabChange('three')}>Refunds</a>              
                {/* <a style={{cursor:'pointer'}} className={ this.state.value === 'four' ? 'active':null}  onClick={()=>this.handleTabChange('four')}>Accounts</a> */}
                 
     </div>

               <div className="tab-pan">
                <div className="tab-bok">

        {value === 'one' && <TabContainer>

          <div width="40%" style={{ width:'70%', alignContent:'center'}} >
          
            <div className="profrm-row" >
             {/* <StaticTextBox value={this.state.bank_name}  label="Bank Name" onChange={this.handleTxtChange('bank_name')}  />  */}
             <NewStaticTextBox label="Bank Name" UpdateData={this.props.UpdateField} field='bank_name' review={this.state.bank_name_review}  value={this.state.bank_name} onChange={this.handleTxtChange('bank_name')}/>
            </div>

            
            <div className="profrm-row">
            {/* <StaticTextBox value={this.state.bank_branch}  label="Branch" onChange={this.handleTxtChange('bank_branch')}  />  */}
            <NewStaticTextBox label="Branch" UpdateData={this.props.UpdateField} field='bank_branch' review={this.state.bank_branch_review}  value={this.state.bank_branch} onChange={this.handleTxtChange('bank_branch')}/>
            </div>

            <div>
            {/* <StaticTextBox value={this.state.bank_account_name}  label="Account Name" onChange={this.handleTxtChange('bank_account_name')}  />  */}
            <NewStaticTextBox label="Account Name" UpdateData={this.props.UpdateField} field='bank_account_name' review={this.state.bank_account_name_review} value={this.state.bank_account_name} onChange={this.handleTxtChange('bank_account_name')}/>
            </div>

            <div>
            {/* <StaticTextBox value={this.state.bank_account}  label="Account Number" onChange={this.handleTxtChange('bank_account')}  />  */}
            <NewStaticTextBox label="Account Number" UpdateData={this.props.UpdateField} field='bank_account' review={this.state.bank_account_review} value={this.state.bank_account} onChange={this.handleTxtChange('bank_account')}/>              
            </div>

            

            
          </div>


        </TabContainer>}
        {value === 'two' && <TabContainer>
        <table style={{textAlignright:'right', width: '100%' }} cellSpacing={0}>
                            <thead style={{ fontSize: 16 }}>
                                      <tr>
                                        <th className={classes.textAlignCenter}   >Invoice Date</th>
                                      
                                        <th className={classes.textAlignCenter}    >Amount(USD)</th>
                                        <th className={classes.textAlignCenter} style={{width:'15%'}}  >Payment</th>
                                        <th className={classes.textAlignCenter} style={{width:'15%'}}  >Payment Date</th>
                                        <th className={classes.textAlignCenter}  >Payment Reference</th>
                                        <th className={classes.textAlignCenter}  ></th>
                                     
                                        <th></th> 
                                      </tr>
                                      
                                  </thead>
                                  <tbody style={{ fontSize:15}}>
                                  {this.props.invoices.map((row,index) => (
                                      <tr key={row.id} style={{marginTop:'10px' }} >
                                        <td className={classes.textAlignCenter} style={{fontWeight:'normal'}} >{row.invoice_date}</td>
                                        <td style={{fontWeight:'normal'}}  >{row.total}</td>
                                        <td style={{fontWeight:'normal'}}  >{this.getRefundStatus(row.status)}</td>
                                        <td style={{fontWeight:'normal'}}  >{row.payment_date}</td>
                                        <td style={{fontWeight:'normal'}}  >{row.payment_reference}</td>
                           
                                     
                                  
                                        <td className={classes.textAlignCenter} style={{width:100 }}>      <Button target="_blank" href={row.download}  variant="contained" color="primary" className={classes.button} style={{background:"#f7a414"}}>
                                        <b style={{fontWeight:500}}>Download</b>
                </Button> </td>
                                      </tr>
                                  ))}     
                                  </tbody>                    
                                </table> 
        </TabContainer>}
      
       
        {value === 'three' && <TabContainer>

        <table style={{textAlignright:'right', width: '100%' }} cellSpacing={0}>
                            <thead style={{ fontSize: 16 }}>
                                      <tr>
                                        <th className={classes.textAlignCenter} style={{width:'40%'}}   >Activity</th>
                                      
                                        <th className={classes.textAlignCenter}   >Amount (USD)</th>
                                        <th className={classes.textAlignCenter}   >Status</th>
                                        <th className={classes.textAlignCenter}   ></th>
                                     
                                        <th></th>
                                      </tr>
                                      
                                  </thead>
                                  <tbody style={{ fontSize:15}}>
                                 {this.props.refunds.map((row,index) => (
                                      <tr key={row.id} style={{marginTop:'10px' }} >
                                        <td className={classes.textAlignCenter} style={{fontWeight:'normal'}} >{row.activity.title}</td>
                                        <td style={{fontWeight:'normal'}}  >{row.amount}</td>
                           
                                        <td className={classes.textAlignCenter} style={{fontWeight:'normal'}}  >{this.getRefundStatus(row.status)}</td>
                                        <td>&nbsp;</td>
                                  
                                        <td className={classes.textAlignCenter} style={{width:100 }}>{ row.status === 0 ? (<Button onClick={()=>{this.showRefund(row)}}  variant="contained" size="small" color="primary" className={classes.button} style={{background:"#f7a414"}}>
                                        <b style={{fontWeight:500}}>Respond</b>
                </Button>):null}  &nbsp;
                { row.status !== 0 ? (<Button onClick={()=>{this.handleView(row)}}  variant="contained" size="small" color="primary" className={classes.button} style={{background:"#f7a414"}}>
                <b style={{fontWeight:500}}>View</b> 
                </Button>):null} </td>
                                      </tr>
                                  ))}     
                                  </tbody>                    
                                </table> 
        </TabContainer>}

</div></div>
      
      </div>
    );
  }
}

TabsWrappedLabel.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TabsWrappedLabel);
